import React from "react";
import {
  breadcrumb,
  ApplicantInfoAFY,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../../components/resources/headerTitlePage/headerTitlePage";
import { TFunction } from "i18next";
import { DataForm, filtersData } from "./applicantInformationDTO";

export const ApplicantInformationView = (props: {
  steps: MenuItem[];
  breadCrumbs: breadcrumb[];
  onClickSaveForm: (data: any) => void;
  onNextStep: () => void;
  onBackStep: () => void;
  t: TFunction;
}) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        titlePage={"Resumen de asegurabilidad del solicitante"}
        steps={props.steps}
        hideStep={false}
        stepsTop={"0px"}
      />
        <div style={{ height: "40px" }}></div>
      <ApplicantInfoAFY
        minHeightGrid="calc(100vh - 145px)"
        //onClickSaveForm={props.onClickSaveForm}
        onClickNext={props.onNextStep}
        onClickPrevious={props.onBackStep}
        //data={DataForm(props.t)}
        filters={filtersData(props.t)}
        previousLabel= "Anterior"
        nextLabel= "Siguiente"
        showInfo= {true}
        hideFilter= {false}
        nextDisabled= {false}
        resumeDescription= "Clientes"
        resumeValue= "$10.00 dollars"
        titleContent= "Datos del solicitante"
        titleDecimalSlider= "Suma asegurada"
        lblAge= "Edad"
        lblHeight= "Altura (pies)"
        lblWeight= "Peso (libras)"
        lblSmoker= "Fumador"
        lblProfession= "Profesión"
        lblOccupation= "Ocupación / Cargo"
        lblCompany= "Empresa"
        lblStatus= "Estado Civil"
        age= {35}
        listStatus= {[
          { id: "1", name: "Soltero" },
          { id: "2", name: "Casado" },
        ]}
      />
    </React.Fragment>
  );
};
