import React, { Suspense, useEffect, useState } from "react";
import { useStyles } from "./style";
import "./style.css"; // @ts-ignore
import {
  breadcrumb,
  ButtonAFY,
  DataFormClient,
  FormBeneficiaryAFY,
  FormConfirmationAFY,
  icons,
  itemPlanProps,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  PDFViewerAFY,
  position,
  select,
  sizes,
  sizesIcon,
  typeAlert,
  typeLoaders,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { themeCore } from "../../assets/themes/theme";
import { PDFViewer } from "../../components/pdfViewer/PDFViewer";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
import * as catalogConst from "../../const/catalog";
import {
  STEP_CONFIRMATIONFORM,
  STEP_PAYMENTCONFIRMATION,
} from "../../const/catalog";
import { formatPhoneNumber } from "../../utils/formats";
import { navigateNext, stepProgress } from "../../dataBase/stepProgress";
import { Customer } from "../../enum/customer";
import { Level } from "../../enum/region";
import { Steps } from "../../enum/steps";
import { PostUploadDocuments } from "../../fnx/apaAuditDocuments/quoteUploadDocuments";
import { GetCatalogBeneficiary } from "../../fnx/catalog/getCatalogBeneficiary";
import { GetCatalogInsurance } from "../../fnx/catalog/getCatalogInsurance";
import { useCertificateQuote } from "../../fnx/certificate/certificateQuotes";
import { getCustomerAction, isCustomer } from "../../fnx/customer";
import { useGetPermissionList } from "../../fnx/getPermissions";
import { GetPersonByDocument } from "../../fnx/person/getPersonByDocument";
import { ReadFlags } from "../../fnx/readFlags";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { showErrorToast } from "../../fnx/showError";
import { ActionFlagsModel } from "../../model/actionFlags";
import {
  TableAdditionalPaymentInfo,
  TableBeneInsured,
} from "../../model/beneficiary";
import { Countries } from "../../model/countries";
import { IdentificationType } from "../../model/identificationType";
import { Person } from "../../model/person";
import { PlanCoverTypes, PlanModel, PlanSelectOption } from "../../model/plan";
import { Region } from "../../model/region";
import {
  formatDocument,
  removeNonNumeric,
  transformToSelect,
} from "../../services/functions";
import { hasPermission } from "../../services/getNavigationPages";
import LocalStorageService from "../../services/localStorage";
import showToast from "../../services/toast";
import { postQuoteUploadDocumentClearData } from "../../store/apapUploadAttachments/actions";
import {
  benefit,
  benefitClearData,
  getBeneficiary,
  getBeneficiryClearData,
  patchBeneficiary,
  patchBeneficiaryClearData,
} from "../../store/beneficiary/actions";
import { certificateQuoteClearData } from "../../store/certificates/actions";
import { countries } from "../../store/countries/actions";
import { quoteClearData, quoteOne } from "../../store/generateQuote/actions";
import { identificationType } from "../../store/identificationType/actions";
import {
  insured,
  patchInsured,
  patchInsuredClearData,
  postInsured,
  postInsuredClearData,
} from "../../store/insured/actions";
import {
  pageActionFlagsApi,
  pageActionFlagsClearData,
} from "../../store/pageActionFlags/actions";
import {
  personByDocumentClearData,
  updatePerson,
} from "../../store/person/actions";
import { catalogGender } from "../../store/plan/actions";
import { plans } from "../../store/plans/actions";
import { projectOne, projectOneClearData } from "../../store/project/actions";
import { region } from "../../store/region/actions";
import {
  formattedDate,
  formattedDateWithTime,
  newFormattedDate,
  newFormattedDateYYMMDD,
} from "../../utils/date";
import {
  formatNumberCustom,
  formatToTwoDecimals,
  parsePrice,
} from "../../utils/getPrice";
import {
  AdditionalDocumentationColumns,
  dataEditVehicleForm,
  getFilters,
  rowsClientDocument,
  StatementColumns,
  Tab1Columns,
  Tab2Columns,
  Tab3Columns,
  TabAutoColumns,
  vehicleDetail,
} from "./confirmationFormDto";
import { ModalEditVehicle } from "./resources/modalEditVehicle";

const STEP = STEP_CONFIRMATIONFORM;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey3 = "afy-action-flags";
const lsPageActionFlags = new LocalStorageService(storageKey3);
const storageKey4 = "afy-steps";
const lsSteps = new LocalStorageService(storageKey4);

const ConfirmationForm = () => {
  const permissions = {
    create: hasPermission("sale-confirmation-form-create"),
  };

  const { t } = useTranslation();
  const classes = useStyles();
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const [triggerPermissionList, setTriggerPermissionList] =
    React.useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [actionFlags, setActionFlags] = React.useState<ActionFlagsModel[]>(
    lsPageActionFlags.getPageActionFlagsStored(),
  );

  const [planId, setPlanId] = useState<string>("");
  // const [filtersDTO, setFiltersDTO] = useState({});
  const [openDrawerUpload, setOpenDrawerUpload] =
    React.useState<boolean>(false);
  const [idDocumentClient, setIdDocumentClient] = React.useState<string>("");
  const [coverTypeId, setCoverTypeId] = useState<string>("");
  const [campaignId, setCampaignId] = useState<string>("");
  const [catalogPaymentMode, setCatalogPaymentMode] = useState<string>("");
  const [catalogStatusQuote, setCatalogStatusQuote] = useState<string>("");
  const [branchId, setBranchId] = useState<string>("");
  const [userId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [uploadTrigger, setUploadTrigger] = React.useState<boolean>(false);
  const isVidaTermino = isCustomer(Customer.VIDA);
  const [period, setPeriod] = React.useState<string>("");
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showInsured, setShowInsured] = React.useState<boolean>(true);
  const [showBeneficiary, setShowBeneficiary] = React.useState<boolean>(true);
  const [addInsured, setAddInsured] = React.useState<boolean>(true);
  const [addBeneficiary, setAddBeneficiary] = React.useState<boolean>(false);
  const [showComponent, setShowComponent] = React.useState<boolean>(false);
  const [openModalBackConfirm, setOpenModalBackConfirm] =
    useState<boolean>(false);
  const [canEdit, setCanEdit] = React.useState<boolean>(true);
  const [certificates, setCertificates] = React.useState<any[]>([]);
  const [rows, setRows] = useState<TableBeneInsured[]>([]);
  const [rows2, setRows2] = useState<TableAdditionalPaymentInfo[]>([]);
  const [rowsBeneficiary, setRowsBeneficiary] = useState<TableBeneInsured[]>(
    [],
  );
  const [hideMarriedName, setHideMarriedName] = React.useState<boolean>(false);
  const [namePlan, setNamePlan] = React.useState<string>("");
  const [isMaskedDocument, setIsMaskedDocument] = useState<boolean>(false);
  const [personQuoteId, setPersonQuoteId] = useState<string>("");
  const [prime, setPrime] = useState<string>("");
  const [currency, setCurrency] = useState<string>("");
  const [insuranceType] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].name
      ? localStorageService
          .getStepStored()
          .steps[Steps.insurance].name.toLowerCase()
      : "",
  );
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [listIdentificationType, setListIdentificationType] = React.useState<
    select[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const { id } = useParams();
  const { isQuote } = useParams();
  const { isPlan } = useParams();
  const { idProject } = useParams();
  const { isDocumentClient } = useParams();
  const [projectTrigger, setProjectTrigger] = React.useState(false);
  const [triggerCatalog, setTriggerCatalog] = React.useState<boolean>(false);
  const [triggerCertificate, setTriggerCertificate] =
    React.useState<boolean>(false);
  const [listGenders, setListGenders] = useState<select[]>([]);
  const [isQuoteType, setIsQuoteType] = useState<boolean>(isQuote === "true");
  const [canEditBeneficiary, setCanEditBeneficiary] = useState<boolean>(false);
  const [canEditInsured, setCanEditInsured] = useState<boolean>(false);
  const [listCountries, setListCountries] = useState<select[]>([]);
  const [listRegionLevel1, setListRegionLevel1] = useState<select[]>([]);
  const [listRegionLevel2, setListRegionLevel2] = useState<select[]>([]);
  const [listRegionLevel3, setListRegionLevel3] = useState<select[]>([]);
  const [quoteId, setQuoteId] = useState<string>("");
  const [page, setPage] = React.useState<number>(1);
  const [idQuoteVehicle, setIdQuoteVehicle] = React.useState<string>("");
  const [productId, setProductId] = React.useState<string>("");
  const [limit, setLimit] = React.useState<number>(10000);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [openModalPdfViewer, setopenModalPdfViewer] =
    React.useState<boolean>(false);
  const [urlDocument, setUrlDocument] = React.useState<string>("");
  const [nameDocument, setNameDocument] = React.useState<string>("");
  const [personId, setPersonId] = React.useState<string>("");
  const [openModalBeneficiary, setOpenModalBeneficiary] =
    React.useState<boolean>(false);
  const [paymentMode, setPaymentMode] = React.useState<any>(
    transformToSelect(
      localStorageService.getStepStored().steps[Steps.plan].paymentMode,
    ),
  );
  const [coverageType, setCoverageType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [insuredType, setInsuredType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [beneficiaryType, setBeneficiaryType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [campaign, setCampaign] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const [planName, setPlanName] = React.useState<string>("");
  const [campaigns, setCampaigns] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const [campaignSelected, setCampaignSelected] = React.useState<any>({
    id:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.id ?? "",
    name:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.name ?? "",
    value:
      localStorageService.getStepStored().steps[Steps.customer].campaignSelected
        ?.id ?? "",
  });

  const [plan, setPlan] = React.useState<any>({
    id: localStorageService.getStepStored().steps[Steps.customer].id,
    name: localStorageService.getStepStored().steps[Steps.customer].name,
  });
  const [paymentSelected, setPaymentSelected] = React.useState<any>();
  const [planSelected, setPlanSelected] = React.useState<any>();
  const [openModalEditVehicle, setOpenModalEditVehicle] =
    React.useState<boolean>(false);

  const [planData, setPlanData] = React.useState<any>({
    titlePage: "Plan",
    viewType: "2",
    titleMetaTag: "Affinity Plan",
    informationPage: {
      backgroundColor: themeCore.colors.background,
      colorText: themeCore.colors.secondaryText,
      description: "Seleccion el plan que necesite el cliente",
      icon: icons.Help,
      iconSize: sizesIcon.standard,
      iconColor: themeCore.colors.primary,
    },
    filter: {
      backgroundColor: "#FFFFFF",
      bgChipInsurers: "#fff",
      bgChipInsurersSelected: "#EBEEF6",
      bgColorButton: "#fff",
      bgColorComparativeButton: "#3A56A1",
      bgColorIconButton: "#EBEEF6",
      bgColorIconButtonSelected: "#3A56A1",
      colorIconButton: "#3A56A1",
      colorIconButtonSelected: "#fff",
      colorIconChip: "#3A56A1",
      colorText: "#3A56A1",
      comparativeTextButton: "ver comparativa",
      comparativeTextColor: "#fff",
      insurers: [],
      title: "Patrocinador",
      titleFirstChip: "Todas",
    },
    data: [],
  });
  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    localStorageService.getStepStored().steps[Steps.insurance].campaign,
  );
  const level1: Level = "REGION_LEVEL1";
  const level2: Level = "REGION_LEVEL2";
  const level3: Level = "REGION_LEVEL3";
  const level4: Level = "REGION_LEVEL4";
  const [coverType, setCoverType] = React.useState<select>();
  const [titleInsured, setTitleInsured] =
    useState<string>("Ingresar asegurado");
  const [titleBeneficiary, setTitleBeneficiary] = useState<string>(
    "Ingresar beneficiario",
  );
  const [projectId, setProjectId] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].id,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [dataFormVehicle, setDataFormVehicle] =
    React.useState(dataEditVehicleForm);
  const [dataForm, setDataForm] = useState<DataFormClient>({
    DocumentNumber: "",
    QuoteNumber: "",
    QuoteStatusName: "",
    QuoteDate: "",
    Type: "",
    PlanTypeName: "",
    CampaignName: "-",
    IdTypeName: "",
    Name: "",
    SecondName: "",
    FirstLastName: "",
    SecondLastName: "",
    MarriedSurName: "-",
    Gender: "",
    Birthday: "",
    Address1: "-",
    Address2: "-",
    CountryName: "",
    ProvinceName: "",
    TownName: "",
    SectorName: "",
    PostalCode: "",
    PhoneOffice: "",
    PhoneHome: "-",
    PhoneNumber: "-",
    Email: "",
  });
  const [dataFormBeneficiary, setDataFormBeneficiary] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    Percentage: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [dataFormInsurance, setDataFormInsurance] = useState<any>({
    Id: "",
    IdType: "",
    idTypeInsured: "",
    DocumentNumber: "",
    Name: "",
    LastNameA: "",
    LastNameB: "",
    marriedName: "",
    Birthday: "",
    PhoneNumber: "",
    IdTypeName: "",
  });
  const [initialPercentage, setInitialPercentage] = useState(45);
  const [trigger, setTrigger] = useState<boolean>(false);
  const [document, setDocument] = useState<number>();
  const [isInsurance, setIsInsurance] = useState<boolean>(false);
  const [listVehiclesDetail, setListVehicles] = useState<vehicleDetail[]>([]);
  const [getCampaign, setGetCampaign] = React.useState([]);
  const [salesFlow, setSalesFlow] = React.useState({});
  const [statementRows, setStatementRows] = React.useState<any>([]);
  const [filters, setFilters] = React.useState({});
  const [profileDocumentsRows, setProfileDocumentsRows] =
    React.useState<any>(rowsClientDocument);
  const [countBadge, setCountBadge] = React.useState<number>(0);
  const [carDetails] = React.useState(
    lsSteps.getStepStored().steps[Steps.vehiclesDetails]?.vehiclesDetails,
  );
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );
  const isApapAuto = getCustomerAction().customerAuto.includes(company);
  const hideTabDocumentClient =
    isApapAuto && (isQuote === "true" || isQuote === undefined);
  const isNotQuote = isQuote !== "false";
  const isApapAutoWithoutQuote = isApapAuto && !isNotQuote;
  const [fileName, setFileName] = React.useState<string>("");
  const [pdfFile, setPdfFile] = React.useState<File>();
  const [modalDocumentClient, setModalDocumentClient] =
    React.useState<boolean>(false);
  const [urlDocumentClient, setUrlDocumentClient] = React.useState<string>("");
  const [docName, setDocName] = React.useState<string>("false");
  const [paymentModelSelected, setPaymentModelSelected] =
    React.useState<select>(
      localStorageService.getStepStored().steps[Steps.customer]
        .paymentModeSelected,
    );
  const [coverTypeSelected, setCoverTypeSelected] = React.useState<select>(
    localStorageService.getStepStored().steps[Steps.customer].coverType,
  );
  const [productType, setProductType] = useState<string>("");
  const projectTypeLocal = React.useRef(
    lsSteps.getStepStored().steps[Steps.insurance].flow?.metadata?.navigation
      .productType,
  );
  const { permissionList } = useGetPermissionList(triggerPermissionList);
  const isApap = getCustomerAction().customerAuto.includes(company);
  const [resOneroject, setResOneroject] = React.useState([]);

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    }),
  );

  const { loadingUpdateQuote, errorUpdateQuote } = useSelector(
    (state: any) => ({
      loadingUpdateQuote: state.UpdateQuote.loadingUpdateQuote,
      errorUpdateQuote: state.UpdateQuote.errorUpdateQuote,
    }),
  );

  const {
    responseCertificateQuote,
    errorCertificateQuote,
    loadingCertificateQuote,
  } = useCertificateQuote(triggerCertificate, "cotizacion", quoteId, 1, 20);

  const {
    responseUploadDocuments,
    errorUploadDocuments,
    loadingUploadDocuments,
  } = PostUploadDocuments(
    uploadTrigger,
    id ? id : quoteId,
    fileName,
    pdfFile,
    idDocumentClient,
  );

  const {
    responseGetCatalogBeneficiary,
    errorGetCatalogBeneficiary,
    loadingGetCatalogBeneficiary,
  } = GetCatalogBeneficiary(triggerCatalog, enable, page, limit, clientId);

  const {
    responseGetCatalogInsurance,
    errorGetCatalogInsurance,
    loadingGetCatalogInsurance,
  } = GetCatalogInsurance(triggerCatalog, enable, page, limit, clientId);

  const { responseIdentificationType, errorIdentificationType } = useSelector(
    (state: any) => ({
      responseIdentificationType:
        state.IdentificationType.responseIdentificationType,
      errorIdentificationType: state.IdentificationType.errorIdentificationType,
    }),
  );

  const { responseCatalogGender, errorCatalogGender } = useSelector(
    (state: any) => ({
      responseCatalogGender: state.Plan.responseCatalogGender,
      errorCatalogGender: state.Plan.errorCatalogGender,
    }),
  );

  const { responseCountries, errorCountries } = useSelector((state: any) => ({
    responseCountries: state.Countries.responseCountries,
    errorCountries: state.Countries.errorCountries,
  }));

  const { responseRegion, errorRegion } = useSelector((state: any) => ({
    responseRegion: state.Region.responseRegion,
    errorRegion: state.Region.errorRegion,
  }));

  const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans?.data,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    }),
  );

  const { responseInsured, errorInsured } = useSelector((state: any) => ({
    responseInsured: state.Insured.responseInsured,
    errorInsured: state.Insured.errorInsured,
  }));

  const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
    responsePlan: state.Plan.responsePlan,
    errorPlan: state.Plan.errorPlan,
    loading: state.Plan.loadingPlan,
  }));

  const { responseUpdatePerson, errorUpdatePerson, loadingUpdatePerson } =
    useSelector((state: any) => ({
      responseUpdatePerson: state.Person.responseUpdatePerson,
      errorUpdatePerson: state.Person.errorUpdatePerson,
      loadingUpdatePerson: state.Person.loadingUpdatePerson,
    }));

  const { responseBenefit, errorBenefit, loadingBenefit } = useSelector(
    (state: any) => ({
      responseBenefit: state.Beneficiary.responseBenefit,
      errorBenefit: state.Beneficiary.errorBenefit,
      loadingBenefit: state.Beneficiary.loadingBenefit,
    }),
  );

  const { responseBeneficiary, errorBeneficiary, loadingBeneficiary } =
    useSelector((state: any) => ({
      responseBeneficiary: state.Beneficiary.responseBeneficiary,
      errorBeneficiary: state.Beneficiary.errorBeneficiary,
      loadingBeneficiary: state.Beneficiary.loadingBeneficiary,
    }));

  const {
    responsePatchBeneficiary,
    errorPatchBeneficiary,
    loadingPatchBeneficiary,
  } = useSelector((state: any) => ({
    responsePatchBeneficiary: state.Beneficiary.responsePatchBeneficiary,
    errorPatchBeneficiary: state.Beneficiary.errorPatchBeneficiary,
    loadingPatchBeneficiary: state.Beneficiary.loadingPatchBeneficiary,
  }));

  const { responsePostInsured, errorPostInsured, loadingPostInsured } =
    useSelector((state: any) => ({
      responsePostInsured: state.Insured.responsePostInsured,
      errorPostInsured: state.Insured.errorPostInsured,
      loadingPostInsured: state.Insured.loadingPostInsured,
    }));

  const { responsePatchInsured, errorPatchInsured, loadingPatchInsured } =
    useSelector((state: any) => ({
      responsePatchInsured: state.Insured.responsePatchInsured,
      errorPatchInsured: state.Insured.errorPatchInsured,
      loadingPatchInsured: state.Insured.loadingPatchInsured,
    }));

  const {
    responsePageActionFlags,
    errorPageActionFlags,
    loadingPageActionFlags,
  } = useSelector((state: any) => ({
    responsePageActionFlags:
      state.PageActionFlags.responsePageActionFlags?.data,
    errorPageActionFlags: state.PageActionFlags.errorPageActionFlags,
    loadingPageActionFlags: state.PageActionFlags.loadingPageActionFlags,
  }));

  const {
    responsePersonByDocument,
    errorPersonByDocument,
    loadingPersonByDocument,
  } = GetPersonByDocument(trigger, document);

  const { responseOneProject, errorOneProject, loadingOneProject } =
    useSelector((state: any) => ({
      responseOneProject: state.Project.responseOneProject?.data,
      errorOneProject: state.Project.errorOneProject,
      loadingOneProject: state.Project.loadingOneProject,
    }));

  useEffect(() => {
    if (errorPersonByDocument) {
      showErrorToast(errorPersonByDocument, setSeeToast, t);
    }
  }, [errorPersonByDocument]);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorInsured) {
      showErrorToast(errorInsured, setSeeToast, t);
    }
  }, [errorInsured]);

  useEffect(() => {
    if (errorCatalogGender) {
      showErrorToast(errorCatalogGender, setSeeToast, t);
    }
  }, [errorCatalogGender]);

  useEffect(() => {
    if (errorCountries) {
      showErrorToast(errorCountries, setSeeToast, t);
    }
  }, [errorCountries]);

  useEffect(() => {
    if (errorRegion) {
      showErrorToast(errorRegion, setSeeToast, t);
    }
  }, [errorRegion]);

  useEffect(() => {
    if (errorUpdateQuote) {
      showErrorToast(errorUpdateQuote, setSeeToast, t);
    }
  }, [errorUpdateQuote]);

  useEffect(() => {
    if (errorPlan) {
      showErrorToast(errorPlan, setSeeToast, t);
    }
  }, [errorPlan]);

  useEffect(() => {
    if (errorBeneficiary) {
      showErrorToast(errorBeneficiary, setSeeToast, t);
    }
  }, [errorBeneficiary]);

  useEffect(() => {
    if (errorIdentificationType) {
      showErrorToast(errorIdentificationType, setSeeToast, t);
    }
  }, [errorIdentificationType]);

  useEffect(() => {
    if (errorUpdatePerson) {
      showErrorToast(errorUpdatePerson, setSeeToast, t);
    }
  }, [errorUpdatePerson]);

  useEffect(() => {
    if (errorPatchBeneficiary) {
      showErrorToast(errorPatchBeneficiary, setSeeToast, t);
    }
  }, [errorPatchBeneficiary]);

  useEffect(() => {
    if (errorPatchInsured) {
      showErrorToast(errorPatchInsured, setSeeToast, t);
    }
  }, [errorPatchInsured]);

  useEffect(() => {
    if (errorBenefit) {
      showErrorToast(errorBenefit, setSeeToast, t);
    }
  }, [errorBenefit]);

  useEffect(() => {
    if (errorPostInsured) {
      showErrorToast(errorPostInsured, setSeeToast, t);
      //setOpenModal(false);
    }
  }, [errorPostInsured]);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  useEffect(() => {
    if (errorUploadDocuments) {
      showErrorToast(errorUploadDocuments, setSeeToast, t);
    }
  }, [errorUploadDocuments]);

  useEffect(() => {
    setProjectTrigger(true);
    if (idProject || projectId) {
      dispatch(
        projectOne({
          id: idProject ? idProject : projectId,
        }),
      );
    }
    setTriggerCatalog(true);

    getActionFlags();

    if (!responseIdentificationType) {
      dispatch(
        identificationType({ enable: enable, page: page, limit: limit }),
      );
    }

    // if (isQuote === "false") {
    //   setSteps(stepProgress(STEP_CONFIRMATIONFORM));
    //   setBreadCrumbs([]);
    // } else {
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));
    // }

    if (!responseCountries) {
      dispatch(countries({ enable: enable, page: page, limit: limit }));
    }
    if (!responseCatalogGender?.data) {
      dispatch(catalogGender({ enable: enable, page: page, limit: limit }));
    }
    //Get Quote //
    const quoteId =
      localStorageService.getStepStored().steps[Steps.customer].quoteId || "";
    if (id !== undefined) {
      dispatch(quoteClearData());
      setCanEdit(isQuoteType);
      //Get the quote by id
      callQuoteOne(id);
    } else {
      callQuoteOne(quoteId);
    }
    callDispatchPlan(null, null);

    if (permissionList?.length === 0) {
      setTriggerPermissionList(true);
    }

    if (permissionList?.length === 0) {
      setTriggerPermissionList(true);
    }
  }, []);

  useEffect(() => {
    if (getCustomerAction().customerAuto.includes(company)) {
      if (carDetails) {
        const car: vehicleDetail = {
          id: 1,
          brand: carDetails.carDetails?.brand?.name,
          model: carDetails.carDetails?.model?.name,
          fueltype: carDetails?.fuelType?.name,
          color: carDetails?.carColor?.name,
          price: carDetails?.price,
          cylinders: carDetails?.numberCylinders?.name,
          plate: carDetails?.registrationNumber,
        };
        const vehicleDet: vehicleDetail[] = [];
        vehicleDet.push(car);
        setListVehicles(vehicleDet);
      }
    }
  }, [carDetails]);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      if (getCustomerAction().customerAuto.includes(company)) {
        const price = parsePrice(
          responseOneQuote.data.contentMap?.deductibleTypeId || 0,
        );
        const newPrice = formatNumberCustom(price || 0);
        if (id !== undefined) {
          const car: vehicleDetail = {
            id: 1,
            brand: responseOneQuote.data.contentMap?.brand?.name,
            model: responseOneQuote.data.contentMap?.model?.name,
            fueltype: responseOneQuote.data.contentMap?.combustion,
            color: responseOneQuote.data.contentMap?.colors?.name,
            price: `RD$ ${newPrice}`,
            cylinders: responseOneQuote.data.contentMap?.cylinders,
            plate: responseOneQuote.data.contentMap?.plate,
          };
          const vehicleDet: vehicleDetail[] = [];
          vehicleDet.push(car);
          setListVehicles(vehicleDet);
        }
      }
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseCertificateQuote && responseCertificateQuote.data?.length > 0) {
      const urlC = responseCertificateQuote.data[0]?.url;
      if (urlC) {
        setUrlDocument(urlC);
        setNameDocument(responseCertificateQuote.data[0]?.title);
        setopenModalPdfViewer(true);
      }
      setTriggerCertificate(false);
      dispatch(certificateQuoteClearData());
    } else if (
      responseCertificateQuote &&
      responseCertificateQuote.data?.length === 0
    ) {
      setTriggerCertificate(false);
      showToastSuccessMessage(
        "No se encontro ningun documento adjunto.",
        typeAlert.warning,
        setSeeToast,
      );
      dispatch(certificateQuoteClearData());
    }
  }, [responseCertificateQuote]);

  useEffect(() => {
    if (errorCertificateQuote) {
      showErrorToast(errorCertificateQuote, setSeeToast, t);
    }
  }, [errorCertificateQuote]);

  useEffect(() => {
    if (responseOneQuote && responseOneQuote?.data) {
      setPersonQuoteId(responseOneQuote?.data?.personId);
      setQuoteId(responseOneQuote?.data?.id);
      setPlanId(responseOneQuote?.data?.planId?.id);
      setCoverTypeId(responseOneQuote?.data?.coverType?.id);
      setCampaignId(responseOneQuote?.data?.campaignId?.id);
      setCatalogPaymentMode(responseOneQuote?.data?.catalogPaymentMode?.id);
      setCatalogStatusQuote(responseOneQuote?.data?.catalogStatusQuote?.id);
      setBranchId(responseOneQuote?.data?.branch);
      setPlanName(responseOneQuote?.data?.planId?.name);

      const gender = listGenders.find(
        (g) => g.id === responseOneQuote?.data?.catalogGender?.id,
      );
      const HideMarriedName = gender?.name === "Femenino" ? false : true;
      setHideMarriedName(HideMarriedName);

      /*date = formattedDate(date);*/

      //Province
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level1,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      //Municipality
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level2,
          parentRegionId: responseOneQuote.data?.municipality?.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );
      //District
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level3,
          parentRegionId: responseOneQuote.data?.district?.parentRegionId,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );

      let status = responseOneQuote.data?.catalogStatusQuote?.description;

      if (status == "Cancelada") {
        status = "Fecha de cancelación";
      } else if (status == "Emitida") {
        status = "Fecha de emisión";
      } else if (status == "Expirada") {
        status = "Fecha de expiración";
      } else {
        status = "Fecha de cotización";
      }
      let date =
        responseOneQuote?.data?.policyId?.effectiveFrom ||
        responseOneQuote?.data?.creationDate;
      let nextYearDate = undefined;
      let originalDate;

      if (date) {
        let originalDate = new Date(date);
        originalDate.setFullYear(originalDate.getFullYear() + 1);
        nextYearDate = formattedDate(originalDate.toISOString());
        nextYearDate = originalDate.toISOString();
      }

      setDataForm({
        DocumentNumber: formatDocument(
          responseOneQuote && responseOneQuote.data?.numberId,
        ),
        IdType: responseOneQuote.data?.identificationTypeId?.id
          ? responseOneQuote.data?.identificationTypeId?.id
          : "",
        Type:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        QuoteNumber: responseOneQuote.data?.policyId
          ? responseOneQuote.data?.policyId.code
          : responseOneQuote && responseOneQuote.data?.code,
        Name: responseOneQuote && responseOneQuote.data?.firstNameA,
        SecondName: responseOneQuote && responseOneQuote.data?.firstNameB,
        FirstLastName: responseOneQuote && responseOneQuote.data?.lastNameA,
        SecondLastName: responseOneQuote && responseOneQuote.data?.lastNameB,
        MarriedSurName: responseOneQuote && responseOneQuote.data?.marriedName,
        Birthday: responseOneQuote && responseOneQuote.data?.dob,
        Address1: responseOneQuote && responseOneQuote.data?.address1,
        Address2: responseOneQuote && responseOneQuote.data?.address2,
        PhoneOffice:
          responseOneQuote && formatPhoneNumber(responseOneQuote.data?.phone2),
        PhoneHome:
          responseOneQuote && formatPhoneNumber(responseOneQuote.data?.phone1),
        PhoneNumber:
          responseOneQuote && formatPhoneNumber(responseOneQuote?.data?.phone3),
        Email: responseOneQuote && responseOneQuote.data?.email,
        QuoteStatusName:
          responseOneQuote &&
          responseOneQuote.data?.catalogStatusQuote?.description,
        QuoteDate: date,
        effectiveDate:
          responseOneQuote && responseOneQuote.data?.policyId
            ? nextYearDate
            : "",
        PlanTypeName: responseOneQuote && responseOneQuote.data?.planId?.name,
        CampaignName:
          responseOneQuote && responseOneQuote.data?.campaignId?.name,
        IdTypeName:
          responseOneQuote &&
          responseOneQuote.data?.identificationTypeId?.description,
        Gender: responseOneQuote && responseOneQuote.data?.catalogGender?.id,
        Country: responseOneQuote && responseOneQuote.data?.nationality?.id,
        CountryName:
          responseOneQuote && responseOneQuote.data?.nationality?.name,
        Province: responseOneQuote && responseOneQuote.data?.province?.id,
        ProvinceName: responseOneQuote && responseOneQuote.data?.province?.name,
        TownName: responseOneQuote && responseOneQuote.data?.municipality?.name,
        Town: responseOneQuote && responseOneQuote.data?.municipality?.id,
        Sector: responseOneQuote && responseOneQuote.data?.district?.id,
        SectorName: responseOneQuote && responseOneQuote.data?.district?.name,
        PostalCode: responseOneQuote && responseOneQuote.data?.zip,
      });

      //setFormVehicle
      setIdQuoteVehicle(responseOneQuote?.data?.contentMap?.idquoteVehicle);
      setProductId(responseOneQuote?.data?.productId?.id);
      let carConditionName;
      switch (responseOneQuote?.data?.contentMap?.condition) {
        case "USED":
          carConditionName = t(`carDetails.carCondition.usado`);
          break;
        case "NEW":
          carConditionName = t(`carDetails.carCondition.nuevo`);
          break;
      }

      if (responseOneQuote?.data?.profileDocuments) {
        const pendingCount = responseOneQuote.data.profileDocuments.filter(
          (doc: any) => doc?.status === "PENDING",
        ).length;
        setCountBadge(pendingCount);
        const dataRows = responseOneQuote?.data?.profileDocuments?.map(
          (item: any) => ({
            id: item.id,
            docType: item.name,
            state: (() => {
              switch (item.status) {
                case "PENDING":
                  return t(`confirmationData.paymentStatuses.PENDING`);
                case "READY":
                  return t(`confirmationData.paymentStatuses.READY`);
              }
            })(),
            date: "",
            user: "",
            documentClientUrl: item.document?.path || "",
          }),
        );
        setProfileDocumentsRows(dataRows);
      }

      let fuelTypeName;
      switch (responseOneQuote?.data?.contentMap?.combustion) {
        case "GAS":
          fuelTypeName = t(`carDetails.fullType.gas`);
          break;
        case "ELECTRIC":
          fuelTypeName = t(`carDetails.fullType.eléctrico`);
          break;
        case "DIESEL":
          fuelTypeName = t(`carDetails.fullType.diesel`);
          break;
        case "GASOLINE":
          fuelTypeName = t(`carDetails.fullType.gasolina`);
          break;
      }
      const countChassis =
        responseOneQuote?.data?.contentMap?.chassisNumber || "";
      const isChassisValid = countChassis?.length !== 17;
      setDataFormVehicle({
        carDetails: [
          {
            brand: {
              id: responseOneQuote?.data?.contentMap?.brand?.id,
              name: responseOneQuote?.data?.contentMap?.brand?.name,
            },
            model: {
              id: responseOneQuote?.data?.contentMap?.model?.id,
              name: responseOneQuote?.data?.contentMap?.model?.name,
            },
            version: {
              id: responseOneQuote?.data?.contentMap?.version?.id,
              name: responseOneQuote?.data?.contentMap?.version?.name,
            },
            class: {
              id: responseOneQuote?.data?.contentMap?.class?.id,
              name: responseOneQuote?.data?.contentMap?.class?.name,
            },
            type: {
              id: responseOneQuote?.data?.contentMap?.type?.id,
              name: responseOneQuote?.data?.contentMap?.type?.name,
            },
            weight: responseOneQuote?.data?.contentMap?.weight,
            tonnage: responseOneQuote?.data?.contentMap?.tonnage,
            passengerCant: responseOneQuote?.data?.contentMap?.passengerCant,
          },
        ],
        year: responseOneQuote?.data?.contentMap?.year,
        carColor: [
          {
            id: responseOneQuote?.data?.contentMap?.colors?.id,
            name: responseOneQuote?.data?.contentMap?.colors?.name,
          },
        ],
        chassisNumber: responseOneQuote?.data?.contentMap?.chassisNumber,
        shortChassis: isChassisValid,
        price: responseOneQuote?.data?.contentMap?.deductibleTypeId,
        numberCylinders: [
          {
            id: responseOneQuote?.data?.contentMap?.cylinders,
            name: responseOneQuote?.data?.contentMap?.cylinders,
          },
        ],
        fuelType: [
          {
            id: responseOneQuote?.data?.contentMap?.combustion,
            name: fuelTypeName,
          },
        ],
        registrationNumber: responseOneQuote?.data?.contentMap?.plate,
        carCondition: [
          {
            id: responseOneQuote?.data?.contentMap?.condition,
            name: carConditionName,
          },
        ],
      });
      //EndFormVehicle

      //setStatementRows
      if (responseOneQuote.data.billing) {
        const dataRows = responseOneQuote?.data?.billing?.map((item: any) => ({
          accountName: item.account,
          amount: `RD$ ${item.amount}`,
          paymentDate: newFormattedDate(item.paymentDate),
          dateExpire: newFormattedDate(item.dateExpire),
          datePaid: newFormattedDate(item.datePaid),
          state: (() => {
            switch (item.statusBilling) {
              case "GENERATED":
                return t(`confirmationData.paymentStatuses.GENERATED`);
              case "EMMITED":
                return t(`confirmationData.paymentStatuses.EMMITED`);
              case "PAID":
                return t(`confirmationData.paymentStatuses.PAID`);
              case "CANCELLED":
                return t(`confirmationData.paymentStatuses.CANCELLED`);
            }
          })(),
        }));
        setStatementRows(dataRows);
      }
      // EndSetStatmentRows

      // if (id) {
      // setPrime(responseOneQuote?.data?.lastPrice?.amount);
      setPrime(
        responseOneQuote?.data?.lastPrice?.amount
          ? formatToTwoDecimals(responseOneQuote.data.lastPrice.amount)
          : "0.00",
      );
      callDispatchPageActionFlags(responseOneQuote?.data?.planId?.projectId);
      if (id) {
        setLocalStorage(responseOneQuote.data, responseOneProject);
        // setSteps(stepProgress(STEP));
        // setBreadCrumbs(stepProgress(STEP, true));
        if (isQuote === "false") {
          setSteps(stepProgress(STEP_PAYMENTCONFIRMATION));
          setBreadCrumbs([]);
        } else {
          setSteps(stepProgress(STEP));
          setBreadCrumbs(stepProgress(STEP, true));
        }
        let certs = responseOneQuote?.data?.certificates;
        //Get the certificates
        if (certs.length > 0) {
          certs = certs.map((c: any, id: any) => {
            return {
              id: c.id,
              name: c.title,
              url: c.url,
            };
          });
        }
        setCertificates(certs);

        //Get the additional payment info
        if (responseOneQuote?.data && isQuote && isQuote === "false") {
          let additionalInfo: TableAdditionalPaymentInfo[] = [];
          additionalInfo.push({
            id: responseOneQuote?.data?.id!,
            accountName: responseOneQuote?.data?.customerAccount?.accountName,
            accountNumber:
              responseOneQuote?.data?.customerAccount?.accountNumber,
            accountType:
              responseOneQuote?.data?.customerAccount?.accountType?.description,
            paymentMode:
              responseOneQuote?.data?.catalogPaymentMode?.description,
            prime: `${responseOneQuote?.data?.lastPrice?.currencySymbol} ${
              responseOneQuote?.data?.lastPrice?.amount ?? "0.00"
            }`,
            lastDigits: "N/A",
          });
          setRows2(additionalInfo);
        }
      } else {
        setRows2([
          {
            id: "1",
            accountName: "N/A",
            accountNumber: "N/A",
            accountType: "N/A",
            paymentMode: "N/A",
            prime: "N/A",
            lastDigits: "N/A",
          },
        ]);
      }
      getInsuredBeneficiary();

      /*Adiciona al resumen la informacion de vehiculos*/
      switch (responseOneQuote?.data?.contentMap?.condition) {
        case "USED":
          carConditionName = t(`carDetails.carCondition.usado`);
          break;
        case "NEW":
          carConditionName = t(`carDetails.carCondition.nuevo`);
          break;
      }
      setFilters({
        ...filtersDTO,
        prime: responseOneQuote?.data?.lastPrice
          ? formatToTwoDecimals(responseOneQuote?.data?.lastPrice?.amount)
          : "0.00000",
        currency: responseOneQuote?.data?.lastPrice
          ? responseOneQuote?.data?.lastPrice?.currencySymbol
          : "RD$",
        year: responseOneQuote?.data?.contentMap?.year,
        model: responseOneQuote?.data?.contentMap?.model?.name,
        brand: responseOneQuote?.data?.contentMap?.brand?.name,
        chassis: responseOneQuote?.data?.contentMap?.chassisNumber,
        plate: responseOneQuote?.data?.contentMap?.plate,
        statusVehicle: carConditionName,
        product: responseOneQuote?.data?.productId?.description,
        plan: responseOneQuote?.data?.planId?.name,
        campaing: responseOneQuote?.data?.campaignId?.name,
        gender: responseOneQuote?.data?.catalogGender?.description,
        birthdate: responseOneQuote?.data?.dob,
      });
    }
    dispatch(projectOneClearData());
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseOneProject) {
      setSalesFlow(responseOneProject.salesFlow);
      setProductType(
        responseOneProject.salesFlow?.metadata?.navigation?.productType,
      );
    }
  }, [responseOneProject]);

  useEffect(() => {
    if (responseGetCatalogBeneficiary && responseGetCatalogBeneficiary?.data) {
      let beneficiaryList: select[] = [];
      responseGetCatalogBeneficiary.data?.map((item: IdentificationType) => {
        beneficiaryList.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });
      setBeneficiaryType(beneficiaryList);

      setDataFormBeneficiary({
        ...dataFormBeneficiary,
        idTypeInsured: beneficiaryList[0].value ?? "",
      });
    }
  }, [responseGetCatalogBeneficiary]);

  useEffect(() => {
    if (responseGetCatalogInsurance && responseGetCatalogInsurance?.data) {
      let insuranceList: select[] = [];
      responseGetCatalogInsurance.data?.map((item: IdentificationType) => {
        insuranceList.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });

      setInsuredType(insuranceList);
      setDataFormInsurance({
        ...dataFormInsurance,
        idTypeInsured: insuranceList[0].value ?? "",
      });
    }
  }, [responseGetCatalogInsurance]);

  useEffect(() => {
    if (responsePlans) {
      const dataST: itemPlanProps[] = [];
      let pm =
        localStorageService.getStepStored().steps[Steps.plan]
          .paymentModeSelected?.name;
      if (period === undefined) {
        setPeriod(pm);
      }
      responsePlans.map((item: PlanModel) => {
        dataST.push({
          id: item.id,
          name: item.name ? item.name.replace(/"/g, "") : "",
          logo: item.sponsorLogoUrl && item.sponsorLogoUrl,
          backgroundColor: "#ffffff",
          currency: "$",
          itemsValue: [],
          option: setCoverTypeStorybook(item.coverTypes ? item.coverTypes : []),
          period: period || pm,
          price: item.price ? item.price?.amount : "0",
          textColor: "#3A56A1",
        });
      });
      setPlanData({ ...plan, data: dataST });
    }
  }, [responsePlans]);

  useEffect(() => {
    if (permissionList?.length > 0) {
      setTriggerPermissionList(false);
    }
  }, [permissionList]);

  useEffect(() => {
    if (
      listIdentificationType.length > 0 &&
      listGenders.length > 0 &&
      listCountries.length > 0 &&
      listRegionLevel1.length > 0 &&
      listRegionLevel2.length > 0 &&
      listRegionLevel3.length > 0 &&
      !showComponent
    ) {
      setShowComponent(true);
    }
  }, [
    listIdentificationType,
    listGenders,
    listCountries,
    listRegionLevel1,
    listRegionLevel2,
    listRegionLevel3,
  ]);

  useEffect(() => {
    if (responsePageActionFlags) {
      lsPageActionFlags.setPageActionFlagsStored(responsePageActionFlags);
      setActionFlags(responsePageActionFlags);
      getActionFlags();
      dispatch(pageActionFlagsClearData());
    } else {
    }
  }, [responsePageActionFlags]);

  useEffect(() => {
    if (responsePatchBeneficiary && responsePatchBeneficiary.data) {
      showToastSuccessMessage(
        t("confirmation.beneficiaryUpdated"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchBeneficiaryClearData());
      getInsuredBeneficiary();
    }
  }, [responsePatchBeneficiary]);

  useEffect(() => {
    if (responsePatchInsured && responsePatchInsured.data) {
      showToastSuccessMessage(
        t("confirmation.insuredUpdated"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(patchInsuredClearData());
      getInsuredBeneficiary();
    }
  }, [responsePatchInsured]);

  useEffect(() => {
    if (responsePostInsured) {
      showToastSuccessMessage(
        t("confirmation.insuredAdded"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModal(false);
      // dispatch(person({ enable: true, page: 1, limit: 1000 }));
      dispatch(postInsuredClearData());
      getInsuredBeneficiary();
      postInsuredClearData();
    }
  }, [responsePostInsured]);

  useEffect(() => {
    if (responseUpdatePerson?.data) {
      localStorageService.setStepStored({
        id: "",
        name: "Confirmation Form",
        step: Steps.confirmData,
        completed: true,
        quoteId: "",
        campaign: "",
      });
      showToastSuccessMessage(
        t("confirmation.added"),
        typeAlert.success,
        setSeeToast,
      );

      if (id !== undefined) {
        dispatch(quoteClearData());
        dispatch(quoteOne({ id: id, fullBody: true }));
      } else {
        dispatch(
          quoteOne({
            id: localStorageService.getStepStored().steps[Steps.customer]
              .quoteId,
            fullBody: true,
          }),
        );
      }
    }
  }, [responseUpdatePerson]);

  useEffect(() => {
    if (responseBenefit && responseBenefit?.data) {
      showToastSuccessMessage(
        t("confirmation.beneficiaryAdded"),
        typeAlert.success,
        setSeeToast,
      );
      setOpenModalBeneficiary(false);
      // dispatch(person({ enable: true, page: 1, limit: 10000 }));
      getInsuredBeneficiary();
      dispatch(benefitClearData());
    }
  }, [responseBenefit]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );

      if (result.length > 0) {
        setPaymentModelSelected(paymentMode[0]);
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (
      responseIdentificationType &&
      responseIdentificationType.data?.length > 0
    ) {
      let idf: select[] = [];
      responseIdentificationType.data?.map((item: IdentificationType) => {
        idf.push({
          id: item.id,
          name: item.description ? item.description : "",
          value: item.id,
        });
      });
      setListIdentificationType(idf);
      setDataFormBeneficiary({
        ...dataFormBeneficiary,
        IdType: idf[0].value ?? "",
      });
      setDataFormInsurance({
        ...dataFormInsurance,
        IdType: idf[0].value ?? "",
      });
    } else {
      //navigate("/customerForm");
    }
  }, [responseIdentificationType]);

  useEffect(() => {
    if (responseCatalogGender && responseCatalogGender.data?.length > 0) {
      const data: select[] = responseCatalogGender.data?.map(
        (gender: PlanModel) => {
          return {
            id: gender.id,
            value: gender.id,
            name: gender.description,
          };
        },
      );
      setListGenders(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCatalogGender]);

  useEffect(() => {
    if (responseCountries && responseCountries.data?.length > 0) {
      const data: select[] = responseCountries.data?.map(
        (country: Countries) => {
          return {
            id: country.id,
            value: country.id,
            name: country.description,
          };
        },
      );
      setListCountries(data);
    } else {
      //navigate("/customerForm");
    }
  }, [responseCountries]);

  useEffect(() => {
    if (responseRegion && responseRegion?.data) {
      const data = responseRegion.data?.map((region: Region) => {
        return {
          id: region.id,
          value: region.id,
          name: region.name,
        };
      });
      data.unshift({ id: "-1", value: "-1", name: " " });
      if (responseRegion.level === level1) {
        setListRegionLevel1(data);
      } else if (responseRegion.level === level2) {
        if (data.length > 0) {
          setListRegionLevel2(data);
        } else {
          setListRegionLevel2([{ id: "-1", value: "-1", name: " " }]);
        }
      } else if (responseRegion.level === level3) {
        if (data.length > 0) {
          setListRegionLevel3(data);
        } else {
          setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
        }
      }
    }
  }, [responseRegion]);

  useEffect(() => {
    if (responseBeneficiary?.data) {
      setBeneficiaryStorybook(responseBeneficiary.data);
    }
  }, [responseBeneficiary?.data]);

  useEffect(() => {
    if (responseInsured?.data) {
      setInsuranceStorybook(responseInsured?.data);
    }
  }, [responseInsured]);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      /* Filtrar por payment mode */
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );
      if (result.length > 0) {
        setPaymentMode(result);
      }

      /* Filtrar por coverage type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      if (result.length > 0) {
        setCoverageType(result);
      }
      /* Filtrar por insured type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_INSURED_TYPE,
      );
      // if (result.length > 0) {
      //   setInsuredType(result);
      //   setDataFormInsurance({
      //     ...dataFormInsurance,
      //     idTypeInsured: result[0].value ?? "",
      //   });
      // }
      /* Filtrar por beneficiary type */
      result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_BENEFICIARY_TYPE,
      );
      // if (result.length > 0) {
      //   setBeneficiaryType(result);
      //   setDataFormBeneficiary({
      //     ...dataFormBeneficiary,
      //     idTypeInsured: result[0].value ?? "",
      //   });
      // }
    }
  }, [responsePlan]);

  useEffect(() => {
    if (actionFlags.length > 0) {
      getActionFlags();
    }
  }, [actionFlags]);

  useEffect(() => {
    if (responsePersonByDocument && responsePersonByDocument.length > 0) {
      const dataPerson = responsePersonByDocument[0];
      const updatedDataFormPerson = updateDataFormPerson(
        dataPerson,
        isInsurance ? dataFormInsurance : dataFormBeneficiary,
      );
      if (isInsurance) {
        setDataFormInsurance(updatedDataFormPerson);
      } else {
        setDataFormBeneficiary(updatedDataFormPerson);
      }
      setTrigger(false);
      dispatch(personByDocumentClearData());
    }
    if (responsePersonByDocument?.length === 0) {
      if (isInsurance) {
        setDataFormInsurance({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      } else {
        setDataFormBeneficiary({
          Name: "",
          SecondName: "",
          LastNameA: "",
          LastNameB: "",
          marriedName: "",
          Birthday: "",
          PhoneNumber: "",
        });
      }
      dispatch(personByDocumentClearData());
    }
  }, [responsePersonByDocument, isInsurance, trigger]);

  useEffect(() => {
    setUploadTrigger(false);
    setOpenDrawerUpload(false);

    dispatch(postQuoteUploadDocumentClearData());
    if (id !== undefined) {
      dispatch(quoteClearData());
      callQuoteOne(id);
    } else {
      callQuoteOne(quoteId);
    }
  }, [responseUploadDocuments]);

  const setCoverTypeStorybook = (ct: PlanCoverTypes[]) => {
    const optionConverType: PlanSelectOption[] = [];
    ct.map((item: PlanCoverTypes) => {
      optionConverType.push({
        name: item.description ? item.description : "",
        value: item.id,
        id: item.id,
      });
    });
    return optionConverType;
  };

  const callQuoteOne = (quoteId: string) => {
    if (quoteId !== "") {
      dispatch(
        quoteOne({
          id: quoteId,
          fullBody: true,
        }),
      );
    }
  };

  const callDispatchPlan = (selectedPM: any, selectedCT: any) => {
    if (selectedPM == null) {
      selectedPM = paymentModelSelected;
    }
    if (selectedCT == null) {
      selectedCT = coverTypeSelected;
    }
    if (paymentMode[0]?.id !== "-1") {
      dispatch(
        plans({
          enable: enable,
          page: page,
          limit: limit,
          projectId: projectId,
          name: namePlan,
          paymentModeId: selectedPM?.id,
          coverTypeId: selectedCT?.id,
          clientId: clientId,
        }),
      );
    }
  };

  const callDispatchPageActionFlags = (id: string) => {
    dispatch(
      pageActionFlagsApi({
        enable: enable,
        page: page,
        limit: limit,
        clientId: clientId,
        projectId: id,
      }),
    );
  };

  const updateDataFormPerson = (dataPerson: Person, currentDataForm: any) => ({
    ...currentDataForm,
    Id: dataPerson.id,
    IdType: dataPerson.identificationTypeId,
    DocumentNumber: dataPerson.numberId,
    Name: dataPerson.firstNameA,
    SecondName: dataPerson.firstNameB,
    LastNameA: dataPerson.lastNameA,
    LastNameB: dataPerson.lastNameB,
    marriedName: dataPerson.marriedName,
    Birthday: dataPerson.dob,
    PhoneNumber: dataPerson.phone1,
  });

  const handleAdd = () => {
    setTitleInsured(t("confirmationData.titleInsured"));
    setIsUpdate(false);
    setOpenModal(true);
    setDataFormInsurance({
      ...dataFormInsurance,
      Id: "",
      DocumentNumber: "",
      Name: "",
      SecondName: "",
      LastNameA: "",
      LastNameB: "",
      marriedName: "",
      Birthday: "",
      PhoneNumber: "",
      IdTypeName: "",
    });
  };

  const setBeneficiaryStorybook = (responseBeneficiary: TableBeneInsured[]) => {
    const beneficiaryRows = responseBeneficiary?.map((b: TableBeneInsured) => ({
      id: b.id,
      type: b.catalogBeneficiaryTypeInfo?.description ?? "",
      percentage: `${b.percentage} %`,
      names: `${b.personInfo?.firstNameA} ${b.personInfo?.firstNameB}`,
      lastNames: `${b.personInfo?.lastNameA} ${b.personInfo?.lastNameB}`,
      marriedName: b.personInfo?.marriedName ?? "",
      phone1: b.personInfo?.phone1 ?? "",
      dob: newFormattedDate(b.personInfo?.dob) ?? "",
      documentNumber: b.personInfo?.numberId ?? "",
      idType: b.personInfo?.identificationTypeId ?? "",
      idTypeInsured: b.catalogBeneficiaryTypeInfo?.id ?? "",
    }));
    setRowsBeneficiary(beneficiaryRows);
    dispatch(getBeneficiryClearData());
  };

  const setInsuranceStorybook = (responseInsured: TableBeneInsured[]) => {
    const beneficiaryRows = responseInsured?.map((I: TableBeneInsured) => ({
      id: I.id,
      type: I.catalogInsuredTypeInfo?.description ?? "",
      names: `${I.personInfo?.firstNameA} ${I.personInfo?.firstNameB}`,
      lastNames: `${I.personInfo?.lastNameA} ${I.personInfo?.lastNameB}`,
      marriedName: I.personInfo?.marriedName ?? "",
      phone1: I.personInfo?.phone1 ?? "",
      dob: newFormattedDate(I.personInfo?.dob) ?? "",
      documentNumber: I.personInfo?.numberId ?? "",
      idType: I.personInfo?.identificationTypeId ?? "",
      idTypeInsured: I.catalogInsuredTypeInfo?.id ?? "",
    }));
    setRows(beneficiaryRows);
    dispatch(patchInsuredClearData());
  };

  const getActionFlags = () => {
    if (actionFlags) {
      const rfShowInsured = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-show-insured",
      );

      const rfAddInsurance = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-insured",
      );

      const rfShowBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      const rfAddBeneficiary = ReadFlags(
        actionFlags ? actionFlags : [],
        "afy-confirm-data-add-beneficiary",
      );

      setShowInsured(rfShowInsured !== "true");

      setAddInsured(
        isQuote !== undefined ? isQuote !== "true" : rfAddInsurance !== "true",
      );

      setShowBeneficiary(rfShowBeneficiary !== "true");

      setAddBeneficiary(
        isQuote !== undefined
          ? isQuote !== "true"
          : rfAddBeneficiary !== "true",
      );

      setCanEditInsured(isQuote === undefined || isQuote === "true");

      setCanEditBeneficiary(isQuote === undefined || isQuote === "true");
    }
  };

  const handleAddBeneficiary = () => {
    setIsUpdate(false);
    setInitialPercentage(45);
    setTitleBeneficiary(t("confirmationData.titleBeneficiary"));
    setOpenModalBeneficiary(true);
    setDataFormBeneficiary({
      ...dataFormBeneficiary,
      Id: "",
      DocumentNumber: "",
      Name: "",
      LastNameA: "",
      LastNameB: "",
      marriedName: "",
      Birthday: "",
      PhoneNumber: "",
      IdTypeName: "",
    });
  };

  const handleUpdatePerson = (dataForm: any) => {
    let body = {
      clientId: clientId,
      identificationTypeId: dataForm.IdType,
      countryId: dataForm.Country,
      catalogGender: dataForm.Gender,
      catalogTitle: "3536c66a-2cf7-44b1-9a81-b7486902b997",
      catalogRegionLevel1: dataForm.Province,
      catalogRegionLevel2: dataForm.Town,
      catalogRegionLevel3: dataForm.Sector,
      catalogCivilStatus: "42bedbc9-91cf-4d40-818e-3fc643e13e51",
      catalogProfession: "811c8769-9e83-42a3-9381-0ae279faf525",
      numberId: dataForm.DocumentNumber,
      firstNameA: dataForm.Name,
      firstNameB: dataForm.SecondName,
      lastNameA: dataForm.FirstLastName,
      lastNameB: dataForm.SecondLastName,
      //merryName: dataForm.MarriedSurName,
      addressA: dataForm.Address1,
      addressB: dataForm.Address2,
      zip: dataForm.PostalCode,
      phone1: removeNonNumeric(dataForm.PhoneHome),
      phone2: removeNonNumeric(dataForm.PhoneOffice),
      phone3: removeNonNumeric(dataForm.PhoneNumber),
      email: dataForm.Email,
      marriedName: hideMarriedName ? "" : dataForm.MarriedSurName,
      dob: formattedDateWithTime(dataForm.Birthday),
    };
    dispatch(
      updatePerson({
        body,
        id: personQuoteId,
      }),
    );
  };

  const handleSubmitBeneficiary = (data: any) => {
    let body = {
      percentage: data.Percentage
        ? data.Percentage.toString()
        : initialPercentage.toString(),
      quoteId: responseOneQuote.data?.id,
      catalogBeneficiaryType: data.idTypeInsured,
      person: {
        identificationTypeId: data.IdType,
        firstNameA: data.Name,
        firstNameB: data.SecondName,
        numberId: data.DocumentNumber.replace(/-/g, ""),
        lastNameA: data.LastNameA,
        lastNameB: data.LastNameB,
        marriedName: data.marriedName,
        phone1: removeNonNumeric(data.PhoneNumber),
        dob: data.Birthday,
        clientId: clientId,
      },
    };
    if (isUpdate) {
      dispatch(patchBeneficiary({ body, id: data.Id }));
      getInsuredBeneficiary();
      setOpenModalBeneficiary(false);
    } else {
      dispatch(benefit(body));
    }
  };

  const handleSubmitInsured = (data: any) => {
    let body = {
      quoteId: responseOneQuote.data?.id,
      catalogInsuredType: data.idTypeInsured,
      person: {
        identificationTypeId: data.IdType,
        firstNameA: data.Name,
        firstNameB: data.SecondName,
        numberId: data.DocumentNumber.replace(/-/g, ""),
        lastNameA: data.LastNameA,
        lastNameB: data.LastNameB,
        marriedName: data.marriedName,
        phone1: removeNonNumeric(data.PhoneNumber),
        dob: data.Birthday,
        clientId: clientId,
      },
    };
    if (isUpdate) {
      dispatch(patchInsured({ body, id: personId }));
      setOpenModal(false);
    } else {
      dispatch(postInsured(body));
    }
  };

  const setLocalStorage = (data: any, project: any) => {
    let insuranceId = data.planId?.projectId;
    let insuranceName = data.planId?.projectInfo?.description || "---";
    let insuranceTypeId = data.planId?.projectInfo?.fkProjectTypeId;
    let insuranceTypeName =
      data.planId?.projectInfo?.fkProjectType.description || "---";
    let planId = data.planId?.id;
    let planName = data.planId?.name || "---";
    let coverTypeId = data.planId?.coverTypes[0]?.id;
    let coverTypeName = data.planId?.coverTypes[0]?.description || "---";
    let paymentModeId = data.catalogPaymentMode.id;
    let paymentModeName = data.catalogPaymentMode.description || "---";
    let campaignId = data.campaignId.id;
    let campaignName = data.campaignId.name || "---";

    localStorageService.setStepStored({
      id: insuranceTypeId,
      name: insuranceTypeName,
      step: Steps.insurance,
      completed: true,
      flow: salesFlow,
    });
    localStorageService.setStepStored({
      id: insuranceId,
      name: insuranceName,
      step: Steps.plan,
      completed: true,
    });
    localStorageService.setStepStored({
      id: planId,
      name: planName,
      step: Steps.customer,
      completed: true,
      quoteId: data.id,
      campaign: campaignId,
      coverType: { id: coverTypeId, name: coverTypeName },
      paymentModeSelected: { id: paymentModeId, name: paymentModeName },
      campaignSelected: { id: campaignId, name: campaignName },
    });
    setCampaignSelected({
      id: campaignId,
      name: campaignName,
      value: campaignId,
    });
    setCampaign([
      {
        id: campaignId,
        name: campaignName,
        value: campaignId,
      },
    ]);
    setCampaigns([{ id: campaignId, value: campaignId, name: campaignName }]);
  };

  const getInsuredBeneficiary = () => {
    if (responseOneQuote && responseOneQuote.data) {
      let _id = responseOneQuote.data?.id;
      if (_id !== undefined) {
        dispatch(
          getBeneficiary({
            enable: true,
            page: 1,
            limit: 100,
            quoteId: _id,
          }),
        );
        dispatch(
          insured({
            enable: true,
            page: 1,
            limit: 100,
            quoteId: _id,
          }),
        );
      }
    }
  };

  const handleNext = (data: any) => {
    //dispatch(updateQuoteClearData());
    //navigate("/checkout/payment-method");
    if (isVidaTermino) {
      navigate("/insurance-policies-summary");
    } else {
      navigateNext(STEP, navigate);
    }
  };

  const handlePrevious = () => {
    if (id === undefined) {
      setOpenModalBackConfirm(true);
    } else {
      if (isPlan) {
        navigate("/");
      } else {
        navigateNext(STEP, navigate);
      }
    }
  };

  const onClickBack = () => {
    if (isDocumentClient === "true") {
      navigate("/pending-info-page/");
    } else {
      handlePrevious();
    }
  };

  const handleCloseBackModal = () => {
    setOpenModalBackConfirm(false);
  };

  const handleBackFromModal = () => {
    navigate("/");
  };

  const filterData = (array: any, key: string, value: string) => {
    let temp: any = [];
    temp = array.filter(function (e: any) {
      return e[key] == value;
    });
    let result: any = [];
    temp.map((item: any) => {
      result.push({ id: item.id, value: item.id, name: item.description });
    });
    return result;
  };

  const handleChangeProvince = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level2,
        parentRegionId: id,
      }),
    );
    setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
  };

  const handleChangeTown = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level3,
        parentRegionId: id,
      }),
    );
  };

  const handleChangeSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    if (name === "ProvinceName") handleChangeProvince(value);
    else if (name === "TownName") handleChangeTown(value);

    const gender = listGenders.find((g) => g.id === value);
    const HideMarriedName = gender?.name === "Femenino" ? false : true;

    setHideMarriedName(HideMarriedName);
  };

  const handleDownload = (obj: any) => {
    window.open(obj.url, "_blank");
  };

  const editBeneficiary = (row: any) => {
    setIsMaskedDocument(false);
    setIsUpdate(true);
    setPersonId(row.row.id);
    setTitleBeneficiary(t("confirmation.editBeneficiary"));
    setOpenModalBeneficiary(true);
    setInitialPercentage(parseFloat(row.row.percentage.replace("%", "")));
    const names = row.row.names.split(" ");
    const firstNameA = names[0] || "";
    const firstNameB = names[1] || "";
    const lastNames = row.row.lastNames.split(" ");
    const lastNameA = lastNames[0] || "";
    const lastNameB = lastNames[1] || "";

    listIdentificationType.map((item: select) => {
      if (item.name === "Pasaporte") {
        if (item.id === row.row.idType) {
          setIsMaskedDocument(true);
        }
      }
    });

    setDataFormBeneficiary({
      ...dataFormBeneficiary,
      Id: row.row.id,
      IdType: row.row.idType,
      DocumentNumber: row.row.documentNumber,
      Name: firstNameA,
      SecondName: firstNameB,
      LastNameA: lastNameA,
      LastNameB: lastNameB,
      marriedName: row.row.marriedName,
      Birthday: newFormattedDateYYMMDD(row.row.dob),
      PhoneNumber: row.row.phone1,
      idTypeInsured: row.row.idTypeInsured,
      Percentage: initialPercentage,
    });
  };

  const editInsured = (row: any) => {
    setIsMaskedDocument(false);
    setIsUpdate(true);
    setPersonId(row.row.id);
    setTitleInsured(t("confirmation.editInsured"));
    const names = row.row.names.split(" ");
    const firstNameA = names[0] || "";
    const firstNameB = names[1] || "";
    const lastNames = row.row.lastNames.split(" ");
    const lastNameA = lastNames[0] || "";
    const lastNameB = lastNames[1] || "";
    setOpenModal(true);

    listIdentificationType.map((item: select) => {
      if (item.name === "Pasaporte") {
        if (item.id === row.row.idType) {
          setIsMaskedDocument(true);
        }
      }
    });

    setDataFormInsurance({
      ...dataFormInsurance,
      Id: row.row.id,
      IdType: row.row.idType,
      DocumentNumber: row.row.documentNumber,
      Name: firstNameA,
      SecondName: firstNameB,
      LastNameA: lastNameA,
      LastNameB: lastNameB,
      marriedName: row.row.marriedName,
      Birthday: newFormattedDateYYMMDD(row.row.dob),
      PhoneNumber: row.row.phone1,
      idTypeInsured: row.row.idTypeInsured,
    });
  };

  const columnsInsurance = Tab1Columns(editInsured, canEditInsured);

  const columnsBeneficiary = Tab2Columns(editBeneficiary, canEditBeneficiary);

  const handleSearch = (
    value: React.ChangeEvent<HTMLInputElement>,
    isInsuranceFlag: boolean,
  ) => {
    const document = value.target.value;
    if (document) {
      const cleanedDocument = document.replace(/-/g, "");
      const numberDocument = Number(cleanedDocument);
      if (!isNaN(numberDocument)) {
        setIsInsurance(isInsuranceFlag);
        setDocument(numberDocument);
        setTrigger(true);
      }
    }
  };

  const searchPerson = (value: React.ChangeEvent<HTMLInputElement>) => {
    handleSearch(value, false);
  };

  const searchInsurancePerson = (
    value: React.ChangeEvent<HTMLInputElement>,
  ) => {
    handleSearch(value, true);
  };

  const handleOnChangePaymentMethod = (event: any) => {
    let paymentPeriod = paymentMode.find((x: any) => x.id === event);

    setPeriod(paymentPeriod?.name);
    setPaymentModelSelected(paymentPeriod);

    //Set selected payment mode
    let pm = paymentMode;
    pm.map((item: any) => {
      item.default = false;
      if (item.id === paymentPeriod.id) {
        item.default = true;
      }
    });
    setPaymentMode(pm);
    callDispatchPlan(paymentPeriod, null);
  };

  const handleOnChangeCoverType = (event: any) => {
    setCoverTypeSelected(event);
    callDispatchPlan(null, event);
  };

  const handleOnChangeCampaign = (event: any) => {
    let campaignS = campaigns.find((x: any) => x.id === event);
    setCampaignSelected(campaignS);
    //callDispatchPlan();
  };

  const handleOnClickAutoDownload = (data: any) => {
    // LLAMA AL API DE COTIZACION
    setTriggerCertificate(true);
    //callDispatchPlan();
  };

  const handleOnClickAutoDownloadVida = (data: any) => {
    // LLAMA
    let certs = responseOneQuote?.data?.certificates;

    const cert = certs.find(
      (cert: any) =>
        cert.certificateDocumentInfo?.physicalName ===
        "suscripcion_individual_vida_termino.docx",
    );
    const urlC = cert?.url;
    if (urlC) {
      setUrlDocument(urlC);
      setopenModalPdfViewer(true);
    }
  };
  const handleOnClickAcquire = (item: any) => {
    if (coverTypeSelected) {
      setCoverType(coverTypeSelected);
    } else {
      setCoverType(item.option[0]);
    }
    let ct = coverTypeSelected ? coverTypeSelected : item.option[0];
    setPaymentSelected(item.paymentMethod);
    setPlanSelected({ id: item.id, name: item.name });
    setPlan({ id: item.id, name: item.name });
    localStorageService.setStepStored({
      id: item.id,
      name: item.name,
      step: Steps.customer,
      completed: true,
      campaign:
        localStorageService.getStepStored().steps[Steps.customer].campaign,
      campaignSelected: campaignSelected,
      paymentMode: paymentMode,
      paymentModeSelected: item.paymentMethod
        ? item.paymentMethod
        : localStorageService.getStepStored().steps[Steps.customer]
            .paymentModeSelected,
      coverType: ct
        ? ct
        : localStorageService.getStepStored().steps[Steps.customer].coverType,
      quoteId:
        localStorageService.getStepStored().steps[Steps.customer].quoteId,
    });
    // setPrime(item.price);
    // setCurrency(item.currency);

    callDispatchPlan(item.paymentMethod, ct);
  };

  const filtersDTO = {
    subdomain: lsLogin.getUserLoginStored().subdomain || "",
    isQuote: isQuote || "",
    currency: currency,
    prime: prime,
    steps: localStorageService.getStepStored().steps,
  };

  const updateVehicle = (e: any) => {
    switch (e.id) {
      case "1":
        setOpenModalEditVehicle(true);
    }
  };

  const quoteUploadDocuments = (file: File[]) => {
    if (file) {
      setFileName(file[0].name.replace(".pdf", ""));
      setPdfFile(file[0]);
      setUploadTrigger(true);
    }
  };

  const handleClickContextMenu = (item: any, params: any) => {
    switch (item.id) {
      case "1":
        setOpenDrawerUpload(true);
        setPdfFile(undefined);
        setFileName("");
        setIdDocumentClient(params?.row?.id);
        break;
      case "2":
        setModalDocumentClient(true);
        setUrlDocumentClient(params?.row?.documentClientUrl);
        setDocName(params?.row?.docType);
        break;
      default:
        break;
    }
  };
  return (
    <React.Fragment>
      {/*//cotizacion*/}
      <PDFViewer
        openModal={openModalPdfViewer}
        setOpenModal={setopenModalPdfViewer}
        documentName={nameDocument}
        urlDocument={urlDocument}
      />
      {/*//documentos cliente*/}
      <PDFViewer
        openModal={modalDocumentClient}
        setOpenModal={setModalDocumentClient}
        documentName={docName}
        urlDocument={urlDocumentClient}
      />
      <ModalEditVehicle
        openModalEditVehicle={openModalEditVehicle}
        setOpenModalEditVehicle={setOpenModalEditVehicle}
        dataFormVehicle={dataFormVehicle}
        callQuoteOneApi={callQuoteOne}
        quoteId={id ? id : quoteId}
        planId={planId}
        coverTypeId={coverTypeId}
        campaignId={campaignId}
        catalogPaymentMode={catalogPaymentMode}
        catalogStatusQuote={catalogStatusQuote}
        branchId={branchId}
        userId={userId}
        idQuoteVehicle={idQuoteVehicle}
        productId={productId}
        t={t}
        planName={planName}
        productType={productType}
        projectTypeLocal={projectTypeLocal}
        salesFlow={salesFlow}
      />
      <LoadingAFY
        loading={
          loadingPostInsured ||
          loadingUpdatePerson ||
          loadingOneQuote ||
          loadingBenefit ||
          loadingPostInsured ||
          loadingPatchBeneficiary ||
          loadingPatchInsured ||
          loadingPersonByDocument ||
          loadingOneProject ||
          loadingBeneficiary ||
          loading ||
          loadingPlans ||
          loadingUpdateQuote ||
          loadingUploadDocuments ||
          loadingGetCatalogBeneficiary ||
          loadingGetCatalogInsurance ||
          loadingCertificateQuote
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingUpdatePerson || loadingUpdateQuote
            ? t("confirmationData.loadingMsgV1")
            : loadingUploadDocuments
            ? t("confirmationData.loadingMsgV3")
            : t("confirmationData.loadingMsgV2")
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <HeaderTitlePage
        breadCrumbs={breadCrumbs}
        steps={steps}
        hideStep={false}
        completed={isQuote === "false" ? true : false}
      />
      <ModalComponentAFY
        openModal={openModal}
        backgroundColor="#ffffff"
        onClose={() => setOpenModal(false)}
        border={0}
        modalWidth={986}
        padding={40}
        modalHeight={"90%"}
      >
        <FormBeneficiaryAFY
          isntMaskedDocument={isMaskedDocument}
          dataForm={dataFormInsurance}
          onClickAcquire={function noRefCheck() {}}
          onClickCancel={() => setOpenModal(false)}
          onClickSave={function noRefCheck() {}}
          onNext={handleSubmitInsured}
          openModal
          subtitle={t("confirmationData.subtitleBeneficiary")}
          title={titleInsured}
          typeInsuredOption={insuredType}
          TypeList={listIdentificationType}
          formsCompleted={[true, false, false]}
          onBlurDocument={(document) => {
            searchInsurancePerson(document);
          }}
          onBlurInput={(document) => {
            searchInsurancePerson(document);
          }}
        />
      </ModalComponentAFY>
      <ModalComponentAFY
        openModal={openModalBeneficiary}
        backgroundColor="#ffffff"
        onClose={() => setOpenModalBeneficiary(false)}
        border={0}
        modalWidth={1170}
        padding={15}
        modalHeight={"90%"}
      >
        <FormBeneficiaryAFY
          isntMaskedDocument={isMaskedDocument}
          dataForm={dataFormBeneficiary}
          initialPercentageBeneficiaries={initialPercentage}
          labelPercentage={t("confirmationData.lblPorcentaje")}
          onClickCancel={() => setOpenModalBeneficiary(false)}
          labelInsuranceOrBeneficiary={t(
            "confirmationData.lblInsuranceBeneficiary",
          )}
          onClickSave={function noRefCheck() {}}
          onNext={handleSubmitBeneficiary}
          openModal
          stepPercentageBeneficiaries={5}
          subtitle={t("confirmationData.subtitle")}
          title={titleBeneficiary}
          typeInsuredOption={beneficiaryType}
          TypeList={listIdentificationType}
          formsCompleted={[true, false, false]}
          showPercentage={true}
          onBlurDocument={(document) => {
            searchPerson(document);
          }}
          onBlurInput={(document) => {
            searchPerson(document);
          }}
        />
      </ModalComponentAFY>
      <ModalComponentAFY
        openModal={openModalBackConfirm}
        backgroundColor="#ffffff"
        onClose={handleCloseBackModal}
        border={0}
        modalWidth={500}
        padding={30}
      >
        <div style={{ marginTop: "10px" }}>
          <h2 className={classes.title}>{t("modal.confirmation")}</h2>
          <p className={classes.description}>
            {t("confirmationData.msgProcessQuestion")}
          </p>
          <div className={classes.buttonsContainer}>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={t("confirmationData.btnBoxNot")}
                onClick={handleCloseBackModal}
              />
            </div>
            <div className={classes.button}>
              <ButtonAFY
                variant={variants.contained}
                size={sizes.large}
                label={t("confirmationData.btnBoxAfirmative")}
                onClick={(e) => {
                  e.stopPropagation();
                  handleBackFromModal();
                }}
              />
            </div>
          </div>
        </div>
      </ModalComponentAFY>
      {showComponent ? (
        <Suspense>
          <FormConfirmationAFY
            isRequired={!isApap}
            tabAdditionalDocumentation={"Documentos Clientes"}
            countBadgeContent={countBadge}
            customBadgeColor={"#fba730"}
            badgeColorText={"#ffffff"}
            additionalDocumentationColumns={AdditionalDocumentationColumns(
              handleClickContextMenu,
            )}
            additionalDocumentationRows={profileDocumentsRows}
            hideMarriedName={hideMarriedName}
            titleDrawer={"Suba el documento solicitado."}
            descriptionDrawer={
              "Arrastra el documento solicitado a la zona de color o haz clic en el área de color para seleccionarlo. Solo puedes subir un documento a la vez, y solo se permiten archivos en formato PDF."
            }
            titleUpload={"Arrastre y suelte su archivo."}
            heightDrawer={"100%"}
            disableSelectCountry={true}
            openDrawerUpload={openDrawerUpload}
            onCloseDrawer={() => {
              setOpenDrawerUpload(false);
            }}
            onUpload={quoteUploadDocuments}
            tabAuto={t("confirmationData.tabAutoTitle")}
            tabAutoDescription={""}
            btnLblDownload={t("confirmationData.btnLblDownload")}
            tabStatement={t(`confirmationData.titleTabStatement`)}
            tabStatementColumns={StatementColumns(t)}
            tabStatementRows={statementRows}
            showButtonDownloadApap={isApapAuto}
            TabAutoColumns={TabAutoColumns(
              updateVehicle,
              isQuote ? isQuote : "",
            )}
            TabAutoRows={listVehiclesDetail}
            onChangeSelect={handleChangeSelect}
            onClickAutoDownload={handleOnClickAutoDownload}
            onClickAutoDownloadVida={handleOnClickAutoDownloadVida}
            labelNumberQuote={
              responseOneQuote?.data?.policyId
                ? t("confirmationData.lblNumberQuote")
                : t("confirmationData.lblNumberQuoteV2")
            }
            title={t("confirmationData.lblTitleConfirmation")}
            identificationList={listIdentificationType}
            GenderList={listGenders}
            CountryList={listCountries}
            ProvinceList={listRegionLevel1}
            TownList={listRegionLevel2}
            SectorList={listRegionLevel3}
            dataForm={dataForm}
            hideTabs={[
              showInsured,
              showBeneficiary,
              !(isQuote === "false"),
              !isApapAuto || isVidaTermino,
              true, //!isApapAutoWithoutQuote, ACTIVAR ESTO PARA CUANDO SE CORRIGA ESTADOS DE CUENTA
              hideTabDocumentClient,
            ]}
            hideAddButton={[addInsured, addBeneficiary]}
            showAdd={true}
            showActions={canEdit}
            showDownloads={!canEdit}
            onClickDownload={handleDownload}
            certificates={certificates}
            dateLabel={
              isQuote !== null && isQuote === "false"
                ? t("confirmationData.lblDate")
                : t("confirmationData.lblDateV2")
            }
            downloadsTitle={t("confirmationData.lblDownloadTitle")}
            labelAdd={t("confirmationData.lblAddBtn")}
            tab1={t("confirmationData.titleTab1")}
            tab1Description={t("confirmationData.descriptionTab1")}
            tab2={t("confirmationData.titleTab2")}
            tab2Description={t("confirmationData.descriptionTab2")}
            tab3={t("confirmationData.titleTab3")}
            tab3Description={t("confirmationData.descriptionTab3")}
            Tab1Columns={columnsInsurance}
            Tab1Rows={rows}
            Tab2Columns={columnsBeneficiary}
            Tab2Rows={rowsBeneficiary}
            Tab3Columns={Tab3Columns}
            Tab3Rows={rows2}
            hide2={true}
            options1={paymentMode}
            options2={[]}
            options3={campaignInfo}
            firstSubtitle={t("confirmationData.firstSubtitle")}
            secondSubtitle={t("confirmationData.secondSubtitle")}
            thirdSubtitle={t("confirmationData.thirdSubtitle")}
            fourthSubtitle={t("confirmationData.fourSubtitle")}
            plan={plan}
            plans={planData.data || []}
            paymentMethodData={paymentMode}
            campaignData={campaign}
            showPaymentMethod={true}
            showCampaign={true}
            onClickAdd={handleAdd}
            onClickRowGrid={() => {}}
            onClickAddBeneficiary={handleAddBeneficiary}
            filters={
              getCustomerAction().customerAuto.includes(company)
                ? getFilters(filters, isVidaTermino, t)
                : getFilters(filtersDTO, false, t)
            }
            titleSelectButton={t("confirmationData.tittleSelectBtn")}
            previousLabel={t("confirmationData.prevLbl")}
            nextLabel={
              !isVidaTermino ? t("confirmationData.nextLbl") : t("finish")
            }
            showPrevious={canEdit}
            nextDisabled={!permissions.create || !canEdit}
            onBack={onClickBack}
            onClickSave={handleUpdatePerson}
            onNext={handleNext}
            onClickPrevious={handlePrevious}
            onBlurInput={() => {}}
            onChangeInput={() => {}}
            onClickCancel={() => {}}
            disableDocumentType={true}
            disableDocumentNumber={true}
            onChangePaymentMethod={handleOnChangePaymentMethod}
            onChangeCampaign={handleOnChangeCampaign}
            onChangeCoverType={handleOnChangeCoverType}
            onClickAcquire={handleOnClickAcquire}
            btnLblDownloadVida="Descargar Solicitud"
            showButtonDownloadVida={isVidaTermino}
            disabledDownloadVida={false}
          />
        </Suspense>
      ) : (
        <div></div>
      )}
    </React.Fragment>
  );
};

export default ConfirmationForm;
