import React, { Suspense, useEffect, useState } from "react";
// @ts-ignore
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { insuranceDTO } from "../../dataBase/insuranceType";
import { getCustomerAction } from "../../fnx/customer";
import { person } from "../../store/person/actions";
import { countries } from "../../store/countries/actions";

import { useDispatch, useSelector } from "react-redux";
import { plans } from "../../store/plans/actions";
import {
  filterData,
  formatDocument,
  removeNonNumeric,
  setCampaignData,
  setPaymentModeData,
  transformToSelect,
} from "../../services/functions";

import {
  breadcrumb,
  icons,
  InsurancePageProps,
  itemPlanProps,
  LoadingAFY,
  MenuItem,
  position,
  select,
  sizesIcon,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { CustomerFormsView } from "./view";
import { useNavigate } from "react-router-dom";
import { Countries } from "../../model/countries";
import { region } from "../../store/region/actions";
import { Level } from "../../enum/region";
import { Region } from "../../model/region";
import * as catalogConst from "../../const/catalog";
import { STEP_CUSTOMERFORM } from "../../const/catalog";
import { catalogGender } from "../../store/plan/actions";
import LocalStorageService from "../../services/localStorage";
import { PlanCoverTypes, PlanModel, PlanSelectOption } from "../../model/plan";
import { quote, quoteClearData } from "../../store/generateQuote/actions";
import { CustomerPageProps } from "../plan/planDto";
import { Steps } from "../../enum/steps";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { showErrorToast } from "../../fnx/showError";
import { useTranslation } from "react-i18next";
import { ListDropdownMultipleProps } from "../../model/user";
import { useGetPlansAll } from "../../fnx/plans/plansGetAll";
import { paymentModeAction } from "../../store/paymentModes/action";
import { getCurrentDatePlusOneYear } from "../../services/getDates";
import { formatToTwoDecimals, parsePrice } from "../../utils/getPrice";

const STEP = STEP_CUSTOMERFORM;
const storageKey2 = "afy-login";
const storageSteps = "afy-steps";
const lsLogin = new LocalStorageService(storageKey2);
const storageKeyVariables = "afy-variables";
const lsVariables = new LocalStorageService(storageKeyVariables);
const lsSteps = new LocalStorageService(storageSteps);

const CustomerForms = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const level1: Level = "REGION_LEVEL1";
  const level2: Level = "REGION_LEVEL2";
  const level3: Level = "REGION_LEVEL3";
  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const storageKeySession = "afy-login";
  const localStorageServiceSession = new LocalStorageService(storageKeySession);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [foundPerson, setFoundPerson] = React.useState<boolean>(false);
  const [variables] = React.useState<any>(lsVariables.getVariablesStored()[0]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [limit, setLimit] = React.useState<number>(500);
  const [insuranceSelected, setInsuranceSelected] = React.useState<string>("");
  const [projectId, setProjectId] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].id,
  );
  const [trigger, setTrigger] = useState(false);
  const [paymentModelSelected, setPaymentModelSelected] =
    React.useState<select>({ id: "", name: "" });
  const [period, setPeriod] = React.useState<string>("");
  const [coverTypeSelected, setCoverTypeSelected] = React.useState<select>();
  const [coverType, setCoverType] = React.useState<select>();
  const [namePlan, setNamePlan] = React.useState<string>("");
  const [renderComponent, setRenderComponent] = React.useState<boolean>(false);
  const [hideMarriedName, setHideMarriedName] = React.useState<boolean>(false);
  const { responsePlansN, errorPlansN, loadingPlansN } = useGetPlansAll(
    trigger,
    clientId,
    projectId,
  );

  const [plan, setPlan] = React.useState<CustomerPageProps>({
    titlePage: "Plan",
    viewType: "2",
    titleMetaTag: "Affinity Plan",
    informationPage: {
      backgroundColor: themeCore.colors.background,
      colorText: themeCore.colors.secondaryText,
      description: "Seleccion el plan que necesite el cliente",
      icon: icons.Help,
      iconSize: sizesIcon.standard,
      iconColor: themeCore.colors.primary,
    },
    filter: {
      backgroundColor: "#FFFFFF",
      bgChipInsurers: "#fff",
      bgChipInsurersSelected: "#EBEEF6",
      bgColorButton: "#fff",
      bgColorComparativeButton: "#3A56A1",
      bgColorIconButton: "#EBEEF6",
      bgColorIconButtonSelected: "#3A56A1",
      colorIconButton: "#3A56A1",
      colorIconButtonSelected: "#fff",
      colorIconChip: "#3A56A1",
      colorText: "#3A56A1",
      comparativeTextButton: "ver comparativa",
      comparativeTextColor: "#fff",
      insurers: [],
      title: "Patrocinador",
      titleFirstChip: "Todas",
    },
    data: [],
  });
  const [campaingSelected, setCampaingSelected] = React.useState<any>();
  const [paymentSelected, setPaymentSelected] = React.useState<any>();
  const [planSelected, setPlanSelected] = React.useState<any>();
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const [insurance, setInsurance] = React.useState<InsurancePageProps>({
    title: "",
    data: [],
  });

  const [listGenders, setListGenders] = useState<select[]>([]);
  const [listCountries, setListCountries] = useState<select[]>([]);
  const [listRegionLevel1, setListRegionLevel1] = useState<select[]>([]);
  const [listRegionLevel2, setListRegionLevel2] = useState<select[]>([]);
  const [listRegionLevel3, setListRegionLevel3] = useState<select[]>([]);
  const [dataForm, setDataForm] = useState<any>({
    Id: "",
    IdType: lsVariables.getVariablesStored()[0]
      ? lsVariables.getVariablesStored()[0].idType
      : "",
    Type: lsVariables.getVariablesStored()[0]
      ? lsVariables.getVariablesStored()[0].type
      : "",
    DocumentNumber: lsVariables.getVariablesStored()[0]
      ? formatDocument(lsVariables.getVariablesStored()[0].docNumber)
      : "",
    Name: "",
    SecondName: "",
    LastName: "",
    MarriedSurName: "",
    Gender: "",
    Birthday: "",
    Country: "",
    Province: "",
    Town: "",
    Sector: "",
    PostalCode: "",
    Address1: "",
    Address2: "",
    PhoneOffice: "",
    PhoneHome: "",
    PhoneNumber: "",
    Email: "",
  });
  const [paymentMode, setPaymentMode] = React.useState<any>(
    transformToSelect(
      localStorageService.getStepStored().steps[Steps.plan].paymentMode,
    ),
  );
  const [coverageType, setCoverageType] = React.useState<any>([
    { id: 1, name: "Sin datos", value: "-1" },
  ]);
  const [campaignInfo, setCampaignInfo] = React.useState<any>(
    transformToSelect(
      localStorageService.getStepStored().steps[Steps.plan].campaign
        ? localStorageService.getStepStored().steps[Steps.plan].campaign
        : localStorageService.getStepStored().steps[Steps.insurance].campaign,
    ),
  );
  const [filters, setFilters] = useState<any[]>([]);
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || "",
  );
  const isApap = getCustomerAction().customerAuto.includes(company);

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    useSelector((state: any) => ({
      responsePaymentMode: state.PaymentMode.responsePaymentMode,
      errorPaymentMode: state.PaymentMode.errorPaymentMode,
      loadingPaymentMode: state.PaymentMode.loadingPaymentMode,
    }));

  const [carDetails] = React.useState(
    lsSteps.getStepStored().steps[Steps.vehiclesDetails].vehiclesDetails,
  );

  const projectType = React.useRef(
    lsSteps.getStepStored().steps[Steps.insurance].flow.metadata.navigation
      .productType,
  );

  const { responsePlan, errorPlan, loading } = useSelector((state: any) => ({
    responsePlan: state.Plan.responsePlan,
    errorPlan: state.Plan.errorPlan,
    loading: state.Plan.loadingPlan,
  }));

  const { responseCampaign } = useSelector((state: any) => ({
    responseCampaign: state.Campaign.responseCampaign,
  }));

  const { responseCountries, errorCountries, loadingCountries } = useSelector(
    (state: any) => ({
      responseCountries: state.Countries.responseCountries,
      errorCountries: state.Countries.errorCountries,
      loadingCountries: state.Countries.loadingCountries,
    }),
  );

  const { responseRegion, errorRegion, loadingRegion } = useSelector(
    (state: any) => ({
      responseRegion: state.Region.responseRegion,
      errorRegion: state.Region.errorRegion,
      loadingRegion: state.Region.loadingRegion,
    }),
  );

  const { responseQuote, errorQuote, loadingQuote } = useSelector(
    (state: any) => ({
      responseQuote: state.Quote.responseQuote,
      errorQuote: state.Quote.errorQuote,
      loadingQuote: state.Quote.loadingQuote,
    }),
  );

  const { responsePerson, errorPerson, loadingPerson } = useSelector(
    (state: any) => ({
      responsePerson: state.Person.responsePerson,
      errorPerson: state.Person.errorPerson,
      loadingPerson: state.Person.loadingPerson,
    }),
  );

  const { responsePlans, errorPlans, loadingPlans } = useSelector(
    (state: any) => ({
      responsePlans: state.Plans.responsePlans?.data,
      errorPlans: state.Plans.errorPlans,
      loadingPlans: state.Plans.loadingPlans,
    }),
  );

  const { responseCatalogGender, errorCatalogGender } = useSelector(
    (state: any) => ({
      responseCatalogGender: state.Plan.responseCatalogGender,
      errorCatalogGender: state.Plan.errorCatalogGender,
    }),
  );

  useEffect(() => {
    if (variables?.docNumber) {
      if (variables?.docNumber.length > 3) {
        handleChangeDocument(variables?.docNumber);
      }
    }
  }, [variables]);

  useEffect(() => {
    if (!responsePlansN?.data) {
      setTrigger(true);
    }
    if (projectId)
      dispatch(paymentModeAction({ enable: enable, page, limit, projectId }));
  }, []);

  useEffect(() => {
    if (responsePlan && responsePlan.data) {
      let result = filterData(
        responsePlan.data,
        "type",
        catalogConst.CATALOG_PAYMENT_MODE,
      );

      if (result.length > 0) {
        setPaymentModelSelected(paymentMode[0]);
      }
    }
  }, [responsePlan]);

  useEffect(() => {
    let campaignSelected;
    if (
      getCustomerAction().customerAuto.includes(
        lsLogin.getUserLoginStored().subdomain || "",
      )
    ) {
      if (
        localStorageService.getStepStored().steps[Steps.insurance].campaign
          .length > 0
      ) {
        campaignSelected =
          localStorageService.getStepStored().steps[Steps.insurance]
            .campaign[0];
      }
    } else {
      campaignSelected =
        localStorageService.getStepStored().steps[Steps.plan].campaignSelected;
    }

    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    if (typeof campaignSelected !== "string") {
      try {
        //setCampaingSelected(handleSubmit as select);
        setCampaingSelected(campaignSelected);
      } catch (error) {
        console.error("Error al parsear campaignSelected:", error);
      }
    }

    if (
      getCustomerAction().customerAuto.includes(
        lsLogin.getUserLoginStored().subdomain || "",
      )
    ) {
      setInsuranceSelected(localStorageService.getStepStored().steps[0].name);
    } else {
      setInsuranceSelected(localStorageService.getStepStored().steps[1].name);
    }

    if (!responseCountries) {
      dispatch(countries({ enable: enable, page: page, limit: limit }));
    }

    dispatch(
      catalogGender({
        enable: enable,
        page: page,
        limit: limit,
        clientId: localStorageServiceSession.getUserLoginStored().clientId,
      }),
    );
  }, []);

  useEffect(() => {
    if (errorCatalogGender) {
      setSeeToast({
        show: true,
        message: errorCatalogGender?.errors[0].message
          ? errorCatalogGender?.errors[0].message
          : errorCatalogGender?.message,
        type: typeAlert.error,
      });
    }
  }, [errorCatalogGender]);

  useEffect(() => {
    if (responseQuote && responseQuote.data) {
      localStorageService.setStepStored({
        id: localStorageService.getStepStored().steps[Steps.plan].id,
        name: localStorageService.getStepStored().steps[Steps.plan].name,
        step: Steps.customer,
        completed: true,
        quoteId: responseQuote.data.id,
        campaign:
          localStorageService.getStepStored().steps[Steps.plan].campaign,
        campaignSelected: campaingSelected,
        paymentMode: paymentMode,
        paymentModeSelected: paymentSelected
          ? paymentSelected
          : localStorageService.getStepStored().steps[Steps.plan]
              .paymentModeSelected,
        coverType: coverType
          ? coverType
          : localStorageService.getStepStored().steps[Steps.plan].coverType,
      });
      //navigate("/confirmationForm");

      if (
        getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || "",
        )
      ) {
        if (
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida termino" ||
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida término"
        ) {
          navigateNext(STEP, navigate);
        } else {
          navigate("/plan-detail-apap-auto");
        }
      } else {
        navigateNext(STEP, navigate);
      }
      dispatch(quoteClearData());
      //navigate(`/confirmationForm/${responseQuote.data.id}/false`);
    }
  }, [responseQuote]);

  useEffect(() => {
    if (errorQuote && errorQuote?.errors) {
      showErrorToast(errorQuote, setSeeToast, t);
      console.log("errorQuote Cleared");
      dispatch(quoteClearData());
    }
    if (errorQuote) {
      showErrorToast(errorQuote, setSeeToast, t);
    }
  }, [errorQuote]);

  useEffect(() => {
    if (errorCountries) {
      showErrorToast(errorCountries, setSeeToast, t);
    }
  }, [errorCountries]);

  useEffect(() => {
    setDataForm({
      ...dataForm,
      Country: localStorageServiceSession.getUserLoginStored().countryId,
    });
  }, [listCountries]);

  useEffect(() => {
    if (errorPerson) {
      showErrorToast(errorPerson, setSeeToast, t);
    }
  }, [errorPerson]);

  useEffect(() => {
    if (errorRegion) {
      showErrorToast(errorRegion, setSeeToast, t);
    }
  }, [errorRegion]);

  const onAcquire = (item: any) => {
    if (coverTypeSelected) {
      setCoverType(coverTypeSelected);
    } else {
      setCoverType(item.option[0]);
    }
    setPaymentSelected(item.paymentMethod);
    setPlanSelected({ id: item.id, name: item.name });

    callDispatchPlan();
  };

  const handleChangeSelect = (event: ListDropdownMultipleProps, id: string) => {
    const value = event.value;
    const name = id;
    if (name === "Province") handleChangeProvince(value);
    else if (name === "Town") handleChangeTown(value);

    setHideMarriedName(event.name === "Femenino" ? false : true);
  };

  const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, id } = event.target;
    //if (id === "DocumentNumber" && value.length > 3)
    // handleChangeDocument(value);
  };

  const handleChangeDocument = (number: string) => {
    dispatch(
      person({ enable: enable, page: page, limit: limit, numberId: number }),
    );
  };

  useEffect(() => {
    if (errorPerson) {
      setSeeToast({
        show: true,
        message: errorPerson?.errors[0].message
          ? errorPerson?.errors[0].message
          : errorPerson?.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [errorPerson]);

  const handleChangeProvince = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level2,
        parentRegionId: id,
        clientId: lsLogin.getUserLoginStored().clientId,
      }),
    );
    setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
  };

  useEffect(() => {
    if (errorRegion) {
      setSeeToast({
        show: true,
        message: errorRegion?.errors[0].message
          ? errorRegion?.errors[0].message
          : errorRegion?.message,
        type: typeAlert.error,
      });
      setTimeout(() => {
        setSeeToast({
          show: false,
          message: "",
          type: typeAlert.error,
        });
      }, 3000);
    }
  }, [errorRegion]);

  const handleChangeTown = (id: string) => {
    dispatch(
      region({
        enable: enable,
        page: page,
        limit: limit,
        level: level3,
        parentRegionId: id,
        clientId: lsLogin.getUserLoginStored().clientId,
      }),
    );
  };

  const handleBack = () => {
    navigateBack(STEP, navigate);
    //navigate("/plan");
  };

  const handleSubmit = (data: any) => {
    if (data) {
      let numberId = data?.DocumentNumber?.replaceAll("-", "");

      const gender = listGenders.find((g) => g.id === data.Gender);
      const hideMarriedName = gender?.name === "Femenino" ? false : true;

      let body: any = {
        numberId: numberId,
        identificationTypeId: data.IdType,
        firstNameA: data.Name,
        firstNameB: data.SecondName,
        lastNameA: data.FirstLastName,
        lastNameB: data.SecondLastName,
        marriedName: hideMarriedName === true ? "" : data.MarriedSurName,
        catalogSexo: data.Gender,
        dob: data.Birthday,
        phone1: removeNonNumeric(data.PhoneHome),
        phone2: removeNonNumeric(data.PhoneOffice),
        phone3: removeNonNumeric(data.PhoneNumber),
        address1: data.Address1,
        address2: data.Address2,
        province: data.Province,
        municipality: data.Town,
        district: data.Sector,
        nationality: data.Country,
        email: data.Email,
        planId: localStorageService.getStepStored().steps[Steps.plan]?.id,
        clientId: clientId,
        zip: data.PostalCode,
        campaignId: campaingSelected?.id,
        catalogPaymentMode: paymentSelected
          ? paymentSelected?.id
          : localStorageService.getStepStored().steps[Steps.plan]
              .paymentModeSelected?.id,
        coverTypeId: coverType
          ? coverType?.id
          : localStorageService.getStepStored().steps[Steps.plan]?.coverType
              ?.id,
        branch: lsLogin.getUserLoginStored().branchId,
        userId: lsLogin.getUserLoginStored().userId,
      };
      if (getCustomerAction().customerAuto.includes(company)) {
        if (
          localStorageService.getStepStored().steps[Steps.insurance]?.flow
            .metadata.navigation.productType === "VIDATERMINO"
        ) {
          if (responsePlansN && responsePlansN.length > 0) {
            body = {
              ...body,
              planId: responsePlansN[0].id,
              coverTypeId: responsePlansN[0].coverTypes[0].id,
              contentType: "VIDA",

              contentMap: {
                productType:
                  localStorageService.getStepStored().steps[Steps.insurance]
                    ?.flow.metadata.navigation.productType,
                brandId:
                  localStorageService.getStepStored().steps[Steps.initialInfo] // // ACA VA EL currency
                    ?.initialInfo.currency?.id,
                modelId:
                  localStorageService.getStepStored().steps[Steps.initialInfo] // // ACA VA EL currency
                    ?.initialInfo.policyDuration, // ACA VA EL policyDuration,
                versionVehicle:
                  localStorageService.getStepStored().steps[Steps.initialInfo] // ACA VA sexType
                    ?.initialInfo.sexType.id,
                combustion: "GASOLINE",
                //colorId: carDetails.carColor.id,
                cylinders: 2,
                //yearVehicle: carDetails.year,
                condition: "NEW",
                //registerVehicle: carDetails.chassisNumber,
                plate: formatToTwoDecimals(
                  localStorageService.getStepStored().steps[Steps.initialInfo]
                    ?.initialInfo?.InsureAmount,
                ),
                //codeId: data.PostalCode,
                branchId: lsLogin.getUserLoginStored().branchId,
                paymentPlanId:
                  localStorageService.getStepStored().steps[Steps.plan]
                    .paymentModeSelected?.id,
                deductibleTypeId:
                  localStorageService.getStepStored().steps[Steps.initialInfo]
                    ?.initialInfo?.premium, // se esta enviando el valor de la prima
                productId: "0",
                passengerCantVehicle: "0",
                weightVehicle: "0",
                tonnageVehicle: "0",
                usageId: "4",
                typeId: "1",
                classId: "1",
                //startDate: dates.startDate,
                //endDate: dates.endDate,
                planId:
                  localStorageService.getStepStored().steps[Steps.initialInfo] // se esta enviado el PLAN TYPE
                    ?.initialInfo?.planType?.id,
              },
              catalogPaymentMode: "aabedf92-8660-4012-924a-88b3760d47b8", //responsePaymentMode.data[0].id,
              campaignId:
                localStorageService.getStepStored().steps[Steps.insurance]
                  ?.campaignSelected.id,
            };
          }
        } else {
          let price = parsePrice(carDetails?.price);
          const dates = getCurrentDatePlusOneYear();

          body = {
            ...body,
            contentType: "VEHICLE",
            catalogPaymentMode: "aabedf92-8660-4012-924a-88b3760d47b8",
            contentMap: {
              productType: projectType.current,
              brandId: carDetails.carDetails.brand.id,
              modelId: carDetails.carDetails.model.id,
              versionVehicle: carDetails.carDetails.version.id,
              combustion: carDetails.fuelType.id,
              colorId: carDetails.carColor.id,
              cylinders: Number(carDetails.numberCylinders.id),
              yearVehicle: carDetails.year,
              condition: carDetails.carCondition.id,
              registerVehicle: carDetails.chassisNumber,
              plate: carDetails.registrationNumber,
              codeId: data.PostalCode,
              branchId: lsLogin.getUserLoginStored().branchId,
              shortChassis: carDetails.shortChassis,
              paymentPlanId:
                localStorageService.getStepStored().steps[Steps.plan]
                  .paymentModeSelected?.id,
              deductibleTypeId: price,
              productId: "0",
              passengerCantVehicle: carDetails.carDetails.passengers,
              weightVehicle: carDetails.carDetails.weight,
              tonnageVehicle: carDetails.carDetails.tonnage,
              usageId: "4",
              typeId: carDetails.carDetails.type.id,
              classId: carDetails.carDetails.class.id,
              startDate: dates.startDate,
              endDate: dates.endDate,
              planId: "0",
            },
          };
        }
      }
      dispatch(quote(body));
    }
  };
  //38038efa-42c2-4b1a-8f79-a43d4858e1d2
  useEffect(() => {
    if (responseCountries?.data) {
      const data: select[] = responseCountries.data?.map(
        (country: Countries) => {
          return {
            id: country.id,
            value: country.id,
            name: country.description,
          };
        },
      );
      setListCountries(data);
      dispatch(
        region({
          enable: enable,
          page: page,
          limit: limit,
          level: level1,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );
    }
  }, [responseCountries]);

  useEffect(() => {
    if (insuranceDTO.data && insuranceDTO.data.length > 0) {
      setInsurance(insuranceDTO);
    }
  }, [insuranceDTO]);

  useEffect(() => {
    if (responseRegion && responseRegion?.data) {
      const data = responseRegion.data?.map((region: Region) => {
        return {
          id: region.id,
          value: region.id,
          name: region.name,
        };
      });
      data.unshift({ id: "-1", value: "-1", name: " " });
      if (responseRegion.level === level1) {
        setListRegionLevel1(data);
      } else if (responseRegion.level === level2) {
        if (data.length > 0) {
          setListRegionLevel2(data);
        } else {
          setListRegionLevel2([{ id: "-1", value: "-1", name: " " }]);
        }
      } else if (responseRegion.level === level3) {
        if (data.length > 0) {
          setListRegionLevel3(data);
        } else {
          setListRegionLevel3([{ id: "-1", value: "-1", name: " " }]);
        }
      }
      console.log("data", responseRegion.level, data.length);
    }
  }, [responseRegion]);

  useEffect(() => {
    if (responseCatalogGender?.data) {
      const data: select[] = responseCatalogGender.data?.map(
        (gender: PlanModel) => {
          return {
            id: gender.id,
            value: gender.id,
            name: gender.description,
          };
        },
      );
      //setGender(data[0].id ?? "");
      /*      setDataForm({
        ...dataForm,
        Gender: data[0].id ?? "",
      });
*/
      setListGenders(data);

      if (
        getCustomerAction().customerAuto.includes(
          lsLogin.getUserLoginStored().subdomain || "",
        )
      ) {
        if (
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida termino" ||
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida término"
        ) {
        } else {
          setFilters([
            {
              name: "Año",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.year ?? "",
              type: "label",
            },
            {
              name: "Modelo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.model.name ?? "",
              type: "label",
            },
            {
              name: "Marca",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.brand.name ?? "",
              type: "label",
            },
            {
              name: "Chasis",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.chassisNumber ?? "",
              type: "label",
            },
            {
              name: "Placa",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.registrationNumber ?? "",
              type: "label",
            },
            {
              name: "Estado del Vehiculo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carCondition.name ?? "",
              type: "label",
            },
          ]);
        }
      }
    }
  }, [responseCatalogGender]);

  const showToast1 = (show: boolean, title: string, type: typeAlert) => {
    setSeeToast({
      show: show,
      message: title,
      type: type,
    });
  };

  useEffect(() => {
    if (
      listGenders.length > 0 &&
      listCountries.length > 0 &&
      listRegionLevel1.length > 0 &&
      foundPerson === true &&
      listRegionLevel2.length > 0 &&
      listRegionLevel3.length > 0
    ) {
      setRenderComponent(true);
    }
  }, [
    listGenders,
    listCountries,
    listRegionLevel1,
    listRegionLevel2,
    listRegionLevel3,
  ]);

  useEffect(() => {
    if (
      listGenders.length > 0 &&
      listCountries.length > 0 &&
      listRegionLevel1.length > 0 &&
      foundPerson === false
    ) {
      setRenderComponent(true);
    }
  }, [listGenders, listCountries, listRegionLevel1]);

  useEffect(() => {
    if (renderComponent === true) {
      setDataForm({
        ...dataForm,
        renderComponent: true,
      });
    }
  }, [renderComponent]);

  useEffect(() => {
    if (responsePerson && responsePerson?.data?.length > 0) {
      setFoundPerson(true);
      const person = responsePerson.data[0];
      handleChangeProvince(person.catalogRegionLevel1);
      handleChangeTown(person.catalogRegionLevel2);

      const gender = listGenders.find((g) => g.id === person.catalogGender);
      const hideMarriedName = gender?.name === "Femenino" ? false : true;
      setHideMarriedName(hideMarriedName);

      setDataForm({
        ...dataForm,
        Id: person.id,
        //IdType: person.identificationTypeId,
        //Country: person.countryId,
        Province: person.catalogRegionLevel1,
        Town: person.catalogRegionLevel2,
        Sector: person.catalogRegionLevel3,
        //DocumentNumber: person.numberId,
        Name: person.firstNameA,
        SecondName: person.firstNameB,
        FirstLastName: person.lastNameA,
        SecondLastName: person.lastNameB,
        MarriedSurName: hideMarriedName === true ? "" : person.marriedName,
        PostalCode: person.zip,
        Address1: person.addressA,
        Address2: person.addressB,
        PhoneOffice: person.phone2,
        PhoneHome: person.phone1,
        PhoneNumber: person.phone3,
        Email: person.email,
        Birthday: person.dob,
        Gender: person.catalogGender,
      });
      handleChangeProvince("");
    } else {
      if (responsePerson?.data !== undefined) {
        setHideMarriedName(true);
        setFoundPerson(false);
        showToast1(true, t("customer.notfound"), typeAlert.info);
      }
    }
  }, [responsePerson]);

  const choiceInsuranceType = (value: string) => {
    navigate("/insurance");
  };

  const setCoverTypeStorybook = (ct: PlanCoverTypes[]) => {
    const optionConverType: PlanSelectOption[] = [];
    ct.map((item: PlanCoverTypes) => {
      optionConverType.push({
        name: item.description ? item.description : "",
        value: item.id,
        id: item.id,
      });
    });
    return optionConverType;
  };

  useEffect(() => {
    if (responsePlans) {
      const dataST: itemPlanProps[] = [];
      let pm =
        localStorageService.getStepStored().steps[Steps.plan]
          .paymentModeSelected?.name;
      if (period === undefined) {
        setPeriod(pm);
      }
      responsePlans.map((item: PlanModel) => {
        dataST.push({
          id: item.id,
          name: item.name ? item.name.replace(/"/g, "") : "",
          logo: item.sponsorLogoUrl && item.sponsorLogoUrl,
          backgroundColor: "#ffffff",
          currency: "$",
          itemsValue: [],
          option: setCoverTypeStorybook(item.coverTypes ? item.coverTypes : []),
          period: period || pm,
          price: item.price ? item.price?.amount : "0",
          textColor: "#3A56A1",
        });
      });
      setPlan({ ...plan, data: dataST });
    }
  }, [responsePlans]);

  const onChangeOptions = (value: any, label: any) => {};

  const callDispatchPlan = () => {
    if (paymentMode[0].id !== "-1") {
      dispatch(
        plans({
          enable: enable,
          page: page,
          limit: limit,
          projectId: projectId,
          name: namePlan,
          paymentModeId: paymentModelSelected.id,
          coverTypeId: coverTypeSelected?.id,
          clientId: clientId,
        }),
      );
    }
  };

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
    }
  }, [errorPlans]);

  const onChangeCoverType = (event: any) => {
    setCoverTypeSelected(event);
  };

  const clickChangePaymentMode = (event: select, item: itemPlanProps) => {
    const t = paymentMode.find((p: any) => p.id === event);
    if (t) {
      setPeriod(t?.name);
      setPaymentModelSelected(t);

      //Set selected payment mode
      let pm = paymentMode;
      pm.map((item: any) => {
        item.default = false;
        if (item.id === t.id) {
          item.default = true;
        }
      });
      setPaymentMode(pm);
    } else {
      const pm = paymentMode.find((p: any) => p.value === event);
      if (pm) {
        setPeriod(t?.name);
        let ms = setPaymentModeData(event, paymentMode);
        setPaymentMode(ms);
        setPaymentModelSelected(pm);
      } else {
        setCampaignData(event);
      }
    }
    callDispatchPlan();
  };

  return (
    <React.Fragment>
      <LoadingAFY
        loading={loading || loadingQuote || loadingRegion}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando..."
        bgColor={themeCore.colors.backgroundLoader}
      />

      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}

      {renderComponent && (
        <Suspense>
          <CustomerFormsView
            hideChangePlan={getCustomerAction().customerAuto.includes(company)}
            hideButtonChangeCampaing={getCustomerAction().customerAuto.includes(
              company,
            )}
            hideMarriedName={hideMarriedName}
            dataForm={dataForm}
            insurance={insurance}
            breadCrumbs={breadCrumbs}
            stepProgress={steps}
            TypeList={[]}
            GenderList={listGenders}
            CountryList={listCountries}
            ProvinceList={listRegionLevel1}
            TownList={listRegionLevel2}
            SectorList={listRegionLevel3}
            hide2={true}
            hide1={true}
            insuranceLabel="Producto"
            insuranceValue={insuranceSelected}
            options1={paymentMode}
            options2={[]}
            options3={campaignInfo}
            plans={plan.data}
            plan={plan}
            showPaymentMethod={true}
            paymentMethodData={paymentMode}
            handleOnBlur={handleOnBlur}
            handleSubmit={handleSubmit}
            handleBack={handleBack}
            onClick={choiceInsuranceType}
            onChangeOptions={onChangeOptions}
            onChangeSelect={handleChangeSelect}
            clickChangePaymentMode={clickChangePaymentMode}
            onChangeCoverType={onChangeCoverType}
            onAcquire={onAcquire}
            filters={filters}
            isApap={isApap}
          />
        </Suspense>
      )}
    </React.Fragment>
  );
};
export default CustomerForms;
