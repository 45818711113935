import {
  LocalStorageProps,
  Step,
  UserLogin,
  Variables,
} from "../interface/localstorage";
import { MenuItem } from "@affinitysystemsgroup/github-packages-affinity-components/dist";

import { NavigateStructure } from "../interface/nav";
import { ActionFlagsModel } from "../model/actionFlags";

import flow from "../assets/flow.json";

class LocalStorageService {
  private storageKey: string;
  private _ls: LocalStorageProps;
  private _lsLogin: UserLogin;
  private _lsStep: any;
  private _mh: MenuItem[];
  private _pageActionFlags: ActionFlagsModel[];
  private _mhSide: NavigateStructure[];
  private _variables: Variables[];

  constructor(storageKey: string) {
    this.storageKey = storageKey;
    this._ls = { email: "", token: "", userId: "", userName: "" };
    this._lsLogin = {
      token: "",
      clientId: "",
      countryId: "",
      branchId: "",
      userId: "",
      roles: [],
      email: "",
      userName: "",
      idToken: {
        jwtToken: "",
        payload: {
          name: "",
          family_name: "",
        },
      },
    };
    this._mh = [];
    this._pageActionFlags = [];
    this._variables = [];
    this._mhSide = [];

    let steps: Step[] = [];

    let index = 0;
    flow.data.flowStep.steps.forEach((step) => {

      let tempStep = {
        step: index,
        id: "",
        name: index.toString(),
        completed: false,
        route: step.route,
        icon: step.icon,
        email: "",
        campaign: "",
        campaignSelected: "",
        paymentMode: "",
        paymentModeSelected: "",
        coverType: "",
        quoteId: "",
        policyId: "",
        vehiclesDetails: "",
        auditDocumentUrl: "",
        financialInformation: "",
        initialInfo: "",
        applicantDeclaration: "",
        medicalInformation: "",
      };
      index++;
      steps.push(tempStep);
    });

  
    this._lsStep = {
      steps: steps,
    };
  }

  public getUserStored(): LocalStorageProps {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._ls = JSON.parse(storedData);
      return this._ls;
    }
    return this._ls;
  }

  public setUserStored(user: string): void {
    const storedData = JSON.parse(user);
    localStorage.setItem(this.storageKey, JSON.stringify(storedData));
  }

  public setLogout(): void {
    localStorage.removeItem(this.storageKey);
  }

  public isAuthenticated(): boolean {
    return !!this.getUserStored().token;
  }

  public getUserLoginStored(): UserLogin {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._lsLogin = JSON.parse(storedData);
      return this._lsLogin;
    }
    return this._lsLogin;
  }

  public setUserLoginStored(userLogin: UserLogin): void {
    localStorage.setItem(this.storageKey, JSON.stringify(userLogin));
  }

  /*Steps*/
  public getStepStored(): any {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._lsStep = JSON.parse(storedData);
      return this._lsStep;
    }
    return this._lsStep;
  }

  public setStepStored(step: Step): void {
    const steps = this.getStepStored();
    const foundStep = steps.steps.find((e: any) => e.step === step.step);

    if (foundStep) foundStep.id = step.id;
    foundStep.name = step.name;
    foundStep.completed = step.completed;
    foundStep.campaign = step.campaign;
    foundStep.coverType = step.coverType;
    foundStep.campaignSelected = step.campaignSelected;
    foundStep.paymentMode = step.paymentMode;
    foundStep.paymentModeSelected = step.paymentModeSelected;
    foundStep.quoteId = step.quoteId;
    foundStep.policyId = step.policyId;
    foundStep.email = step.email;
    foundStep.route = step.route;
    foundStep.icon = step.icon;
    foundStep.flow = step.flow;
    foundStep.vehiclesDetails = step.vehiclesDetails;
    foundStep.auditDocumentUrl = step.auditDocumentUrl;
    foundStep.initialInfo = step.initialInfo;
    foundStep.applicantDeclaration = step.applicantDeclaration;
    foundStep.financialInformation = step.financialInformation;
    foundStep.medicalInfo = step.medicalInfo;
    foundStep.applicantInformation = step.applicantInformation;

    //const storedData = JSON.parse(steps);
    localStorage.setItem(this.storageKey, JSON.stringify(steps));
  }

  /*END Steps*/

  /*Set Menu Options*/
  public setMenuHorizontalStored(menuHorizontal: MenuItem[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuHorizontal));
  }

  public getMenuHorizontalStored(): MenuItem[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mh = JSON.parse(storedData);
      return this._mh;
    }
    return this._mh;
  }

  /*End*/

  /*Set Page Action Flags*/
  public setPageActionFlagsStored(pageActionFlags: ActionFlagsModel[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(pageActionFlags));
  }

  public getPageActionFlagsStored(): ActionFlagsModel[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._pageActionFlags = JSON.parse(storedData);
      return this._pageActionFlags;
    }
    return this._pageActionFlags;
  }

  public setVariablesStored(variables: any): void {
    console.log("variables", variables);
    console.log(this.storageKey);
    console.log(JSON.stringify(variables));
    localStorage.setItem(this.storageKey, JSON.stringify(variables));
  }

  public getVariablesStored(): any {
    const storedData = localStorage.getItem(this.storageKey);

    if (storedData) {
      this._variables = JSON.parse(storedData);
      return this._variables;
    }
    return this._variables;
  }

  /*Set Menu SideBar*/
  public setMenuSideBarStored(menuSideBar: NavigateStructure[]): void {
    localStorage.setItem(this.storageKey, JSON.stringify(menuSideBar));
  }

  public getMenuSideBarStored(): NavigateStructure[] {
    const storedData = localStorage.getItem(this.storageKey);
    if (storedData) {
      this._mhSide = JSON.parse(storedData);
      return this._mhSide;
    }
    return this._mhSide;
  }

  /*End*/
}

export default LocalStorageService;
