import {
  GET_CAR_COLOR,
  GET_CAR_COLOR_CLEAR_DATA,
  GET_CAR_COLOR_ERROR,
  GET_CAR_COLOR_SUCCESS,
  GET_VEHICLES,
  GET_VEHICLES_CLEAR_DATA,
  GET_VEHICLES_ERROR,
  GET_VEHICLES_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingGetVehicles: false,
  responseGetVehicles: null,
  errorGetVehicles: null,

  loadingCarColor: false,
  responseCarColor: null,
  errorCarColor: null,
};

const vehicles = (state = initialState, action) => {
  switch (action.type) {
    case GET_VEHICLES:
      state = {
        ...state,
        loadingGetVehicles: true,
        responseGetVehicles: null,
        errorGetVehicles: null,
      };
      break;
    case GET_VEHICLES_SUCCESS:
      state = {
        ...state,
        loadingGetVehicles: false,
        responseGetVehicles: {
          ...state.responseGetVehicles,
          data: action.payload.data.map((vehicle) => ({
            brand: vehicle.brand,
            model: vehicle.model,
            version: vehicle.version,
            type: vehicle.type,
            class: vehicle.class,
            transmission: vehicle.transmission,
            cylinderCapacity: vehicle.cylinder_capacity,
            weight: vehicle.weight,
            tonnage: vehicle.tonnage,
            passengers: vehicle.passengers,
          })),
        },
        errorGetVehicles: null,
      };
      break;
    case GET_VEHICLES_ERROR:
      state = {
        ...state,
        loadingGetVehicles: false,
        responseGetVehicles: null,
        errorGetVehicles: action.payload,
      };
      break;
    case GET_VEHICLES_CLEAR_DATA:
      state = {
        ...state,
        loadingGetVehicles: null,
        responseGetVehicles: false,
        errorGetVehicles: null,
      };
      break;
    //End get vehicles
    case GET_CAR_COLOR:
      state = {
        ...state,
        loadingCarColor: true,
        responseCarColor: null,
        errorCarColor: null,
      };
      break;
    case GET_CAR_COLOR_SUCCESS:
      state = {
        ...state,
        loadingCarColor: false,
        responseCarColor: action.payload,
        errorCarColor: null,
      };
      break;
    case GET_CAR_COLOR_ERROR:
      state = {
        ...state,
        loadingCarColor: false,
        responseCarColor: null,
        errorCarColor: action.payload,
      };
      break;
    case GET_CAR_COLOR_CLEAR_DATA:
      state = {
        ...state,
        loadingCarColor: null,
        responseCarColor: false,
        errorCarColor: null,
      };
      break;
    // END GET CAR COLOR
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default vehicles;
